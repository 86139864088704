import React, { useEffect } from "react";
import { Table } from "antd";

const twoPointDecimal = (number) => Number(number).toFixed(2);

const OutputCostsTable = ({iraData}) => {

    useEffect(() => console.log(iraData, 'iraData'), [iraData])

    return (
        <span onClick={(e) => { e.preventDefault(); e.stopPropagation() }}  >
            <Table
                rowKey="id"
                bordered
                size="small"
                pagination={false}
                columns={[
                    {
                        title: "Br. računa",
                        dataIndex: "invoiceNumber",
                        key:"invoiceNumber"
                    },
                    {
                        title: "Način uplate",
                        dataIndex: "paymentType",
                        key:"paymentType"

                    },
                    {
                        title: "Datum kreiranja",
                        dataIndex: "creationDate",
                        key: "creationDate"
                    },
                    {
                        title: "Datum uplate",
                        dataIndex: "paymentDate",
                        key: "paymentDate"
                    },
/*                    {
                        title: "Iznos stavke",
                        dataIndex: "itemPrice",
                        render: (value => (<>{`${twoPointDecimal(value)} €`}</>)),
                        key: "itemPrice"
                    },*/
                    {
                        title: "Iznos uplate",
                        dataIndex: "paymentAmount",
                        render: (value => (<>{`${twoPointDecimal(value)} €`}</>)),
                        key: "paymentAmount"
                    },
                ]}
                scroll={{x: 700}}
                dataSource={iraData?.iraRecapitulationList}
                summary={() => (
                    <Table.Summary>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4}>
                                <b>SUMA</b>
                            </Table.Summary.Cell>
{/*                            <Table.Summary.Cell index={3} colSpan={1}>
                                {`${iraData?.itemPriceSum ? twoPointDecimal(iraData?.itemPriceSum) : "0.00"} €`}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5} colSpan={1}>
                                {`${iraData?.paymentSum ? twoPointDecimal(iraData?.paymentSum) : "0.00"} €`}
                            </Table.Summary.Cell>*/}
                            <Table.Summary.Cell index={0} colSpan={4}>
                                {`${iraData?.paymentSum ? twoPointDecimal(iraData?.paymentSum) : "0.00"} €`}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4}>
                                <b>POSTOTAK UPLATE</b>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={0} colSpan={4}>
                                {`${iraData?.paymentPercentage ? twoPointDecimal(iraData?.paymentPercentage) : "0.00"} %`}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            ></Table>
        </span>);
}
export default OutputCostsTable;