import React, {useEffect, useState} from "react";
import {Collapse, Form, Image, Input, Select, theme, Upload} from "antd";

import {isOibValid} from "../../../../utils/validationUtil.js";
import "./CompanyAdministrationForm.css";
import {axiosInstance} from "../../../../api/axiosInstance.js";
import {cities} from "../../../../constants/constants.js";
import {CaretRightOutlined, PlusOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const CompanyAdministrationForm = ({
                                       form,
                                       mode,
                                       initialValues,
                                       owners,
                                       onFinish,
                                       disabled,
                                       fileList,
                                       setUploadedImage,
                                       previewImage,
                                       setPreviewImage,
                                       setFileList,
                                       previewOpen,
                                       setPreviewOpen,
                                       activeKey,
                                       setActiveKey
                                   }) => {
    const {token} = theme.useToken();

    const handleSetActiveKey = (selectedKey) => {
        let activeKeyCopy = [...activeKey];
        const keyAlreadyExists = activeKeyCopy.includes(selectedKey);
        if (keyAlreadyExists) {
            const index = activeKeyCopy.indexOf(selectedKey);
            if (index > -1) {
                activeKeyCopy.splice(index, 1);
            }
        } else {
            activeKeyCopy.push(selectedKey);
        }
        setActiveKey(activeKeyCopy);
    };

    const formStyle = {
        maxWidth: "none",
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues]);

    useEffect(() => {
        if (mode === "insert") {
            form.resetFields();
        }
    }, [mode]);

    const validateOIB = (rule, value, callback) => {
        if (isOibValid(value)) {
          /*  if (mode !== "edit") {
                axiosInstance
                    .get(`/api/v1/companies/check-oib-exists/${value}`)
                    .then((res) => {
                        if (res.data) {
                            callback("Već postoji tvrtka sa istim OIB-om!");
                        } else {
                            callback();
                        }
                    });
            } else {
                callback();
            }*/
            callback();
        } else {
            callback("Neispravan OIB");
        }
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        if (mode === 'insert' || mode === 'update') {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
                setPreviewImage(file.url || file.preview);
            }
        }
        setPreviewOpen(true);
    };

    const handleChange = ({fileList: newFileList}) => setFileList(newFileList);

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined/>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Učitajte sliku
            </div>
        </button>
    );

    const {Panel} = Collapse;

    const panelStyle = {
        marginBottom: 0,
        borderRadius: token.borderRadiusLG,
        border: "none",
    };

    return (
        <Form
            form={form}
            name="companyAdministrationForm"
            style={formStyle}
            onFinish={(values) =>
                onFinish({
                    ...values,
                    id: initialValues?.id,
                    address: {...values.address, id: initialValues?.address?.id},
                    owner: {...values.owner, id: values.owner.id},
                })
            }
            initialValues={initialValues}
            layout={"vertical"}
            disabled={disabled}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <Collapse
                bordered={false}
                expandIcon={({isActive}) => (
                    <CaretRightOutlined
                        rotate={isActive ? 90 : 0}
                        style={{
                            background: token.colorBgContainer,
                        }}
                    />
                )}
                style={{marginTop: "4px"}}
                activeKey={activeKey}
                ghost
            >
                <Panel
                    forceRender={true}
                    key="1"
                    header="Opći podaci"
                    style={{...panelStyle}}
                    onClick={(e) => {
                        handleSetActiveKey("1");
                    }}
                >
                    <div onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                        <Form.Item
                            name={`name`}
                            label={`Naziv:`}
                            rules={[
                                {
                                    required: true,
                                    message: "Naziv je obavezno polje!",
                                },
                            ]}
                        >
                            <Input placeholder="Naziv tvrtke"/>
                        </Form.Item>
                        <Form.Item
                            hasFeedback={true}
                            name={`oib`}
                            label={`OIB:`}
                            rules={[
                                {
                                    required: true,
                                    message: "OIB je obavezno polje!",
                                },
                                {
                                    validator: (rule, value, callback) =>
                                        validateOIB(rule, value, callback),
                                },
                            ]}
                        >
                            <Input placeholder="OIB tvrtke" maxLength={11}/>
                        </Form.Item>
                        <Form.Item
                            name={["address", "street"]}
                            label={`Ulica:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Ulica je obavezno polje!",
                                },
                            ]}
                        >
                            <Input placeholder="Ulica tvrtke"/>
                        </Form.Item>
                        <Form.Item
                            name={["address", "houseNumber"]}
                            label={`Kućni broj:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Kućni broj je obavezno polje!",
                                },
                            ]}
                        >
                            <Input placeholder="Kućni broj tvrtke"/>
                        </Form.Item>
                        <Form.Item
                            name={["address", "postNumber"]}
                            label={`Poštanski broj:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Poštanski broj je obavezno polje!",
                                },
                            ]}
                        >
                            <Input placeholder="Poštanski broj tvrtke"/>
                        </Form.Item>
                        <Form.Item
                            name={["address", "city"]}
                            label={`Grad:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Grad je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={cities}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name={["owner", "id"]}
                            label={`Vlasnik tvrtke:`}
                            rules={[
                                {
                                    required: true,
                                    message: "Vlasnik tvrtke je obavezno polje!",
                                },
                            ]}
                        >
                            <Select options={owners}></Select>
                        </Form.Item>
                    </div>

                </Panel>
                <Panel
                    forceRender={true}
                    key="2"
                    header="Detaljni podaci"
                    style={{...panelStyle}}
                    onClick={(e) => {
                        handleSetActiveKey("2");
                    }}>
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault()
                        }}
                    >
                        <Form.Item
                            name={"seatOfCourt"}
                            label={"Sjedište trgovačkog suda"}
                            rules={[
                                {
                                    required: true,
                                    message: "Sjedište trgovačkog suda je obavezno polje!",
                                }
                            ]}
                        >
                            <TextArea placeholder={"Sjedište trgovačkog suda"}></TextArea>
                        </Form.Item>
                        <Form.Item
                            label={"MBS"}
                            name={`mbs`}
                            rules={[
                                {
                                    required: true,
                                    message: "MBS je obavezno polje!",
                                }
                            ]}
                        >
                            <Input placeholder="MBS"></Input>
                        </Form.Item>
                        <Form.Item
                            label={"IBAN"}
                            name={`iban`}
                            rules={[
                                {
                                    required: true,
                                    message: "IBAN je obavezno polje!",
                                }
                            ]}
                        >
                            <Input placeholder="IBAN"/>
                        </Form.Item>
                        <Form.Item
                            name={"bankName"}
                            label={"Naziv banke"}
                            rules={[
                                {
                                    required: true,
                                    message: "Naziv banke je obavezno polje!",
                                }
                            ]}
                        >
                            <TextArea placeholder={"Naziv banke"}></TextArea>
                        </Form.Item>
                        <Form.Item
                            name={"bankAddress"}
                            label={"Adresa banke"}
                            rules={[
                                {
                                    required: true,
                                    message: "Adresa banke je obavezno polje!",
                                }
                            ]}
                        >
                            <TextArea placeholder={"Puna adresa u jednom polju sa ulicom, kućanskim brojem, poštanskim brojem i nazivom mjesta"}></TextArea>
                        </Form.Item>
                        <Form.Item
                            name={"boardMembers"}
                            label={"Članovi uprave"}
                            rules={[
                                {
                                    required: true,
                                    message: "Članovi uprave je obavezno polje!",
                                }
                            ]}
                        >
                            <TextArea placeholder={"Imena i prezimena odvajaju se zarezom"}></TextArea>
                        </Form.Item>
                        <Form.Item
                            name={"shareCapital"}
                            label={"Temeljni kapital"}
                            rules={[
                                {
                                    required: true,
                                    message: "Temeljni kapital je obavezno polje!",
                                }
                            ]}
                        >
                            <Input placeholder={"Temeljni kapital"}></Input>
                        </Form.Item>
                        <Form.Item
                            name={"shareCapitalCurrency"}
                            label={`Valuta temeljnog kapitala:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Valuta temeljnog kapitala je obavezno polje!",
                                },
                            ]}
                        >
                            <Select
                                options={[{ value: 'kn', label: "KN" }, { value: "eur", label: "EUR" }]}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                }
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name={"logo"}
                            label={`Logo:`}
                            rules={[
                                {
                                    required: false,
                                    message: "Logo tvrtke je obavezno polje!",
                                },
                            ]}
                        >
                            <Upload
                                accept=".png,.jpg,.jpeg"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                maxCount={1}
                                beforeUpload={(file, fileList) => {
                                    getBase64(file).then(res => {
                                        setUploadedImage(res);
                                    });
                                    return false;
                                }}
                            >
                                {fileList.length > 0 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{
                                        display: 'none',
                                    }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        //afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </Form.Item>
                    </div>
                </Panel>
            </Collapse>
        </Form>
    );
};

export default CompanyAdministrationForm;
