export const API_URL = process.env.REACT_APP_API_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const AUTHORIZATION_SERVICE_URL =
  process.env.REACT_APP_AUTHORIZATION_SERVICE_URL;
export const AUTH_ENDPOINT = AUTHORIZATION_SERVICE_URL + "/oauth2/v1/authorize";
export const TOKEN_ENDPOINT = AUTHORIZATION_SERVICE_URL + "/oauth2/v1/token";
export const TOKEN_REVOCATION_ENDPOINT =
  AUTHORIZATION_SERVICE_URL + "/oauth2/v1/revoke";
export const CLIENT_NAME = "oidc-client";
export const CLIENT_SECRET = "secret";


const cityArr = [
  "Ada",
  "Adžamovci",
  "Alaginci",
  "Alan",
  "Aleksinica",
  "Alilovci",
  "Aljmaš",
  "Amatovci",
  "Andigola",
  "Andraševec",
  "Andrijaševci",
  "Andrijevac",
  "Andrilovec",
  "Antenal",
  "Antin",
  "Antolovec",
  "Antonci",
  "Antunovac",
  "Anžići",
  "Apatija",
  "Apatovec",
  "Apševci",
  "Arapovac",
  "Arbanija",
  "Aržano",
  "Auguštanovec",
  "Ašikovci",
  "Babina Greda",
  "Babina Rijeka",
  "Babinac",
  "Babindub",
  "Babinec",
  "Babino Polje",
  "Babići",
  "Babotok",
  "Badanj",
  "Baderna",
  "Badličan",
  "Badljevina",
  "Badovinci",
  "Badžula",
  "Bair",
  "Baići",
  "Bajagić",
  "Bajkini",
  "Bajčići",
  "Bakar",
  "Bakar - dio (Sveti Kuzam)",
  "Bakarac",
  "Bakić",
  "Bakovac Kosinjski",
  "Bakovčica",
  "Baldaši",
  "Bale",
  "Balinac",
  "Balinci",
  "Balići I",
  "Balići II Dio",
  "Baljci",
  "Bandino Selo",
  "Banfi",
  "Banićevac",
  "Banići",
  "Baničevec",
  "Banj",
  "Banja",
  "Banje Selo",
  "Banjevci",
  "Banjol",
  "Banjole",
  "Banjsko Selo",
  "Banki",
  "Bankovci",
  "Banova Jaruga",
  "Banovci",
  "Banovina",
  "Banovo",
  "Banska Gorica",
  "Banska Selnica",
  "Banski Kovačevac",
  "Banski Moravci",
  "Bansko Vrpolje",
  "Banšćica",
  "Bapska",
  "Bapča",
  "Baranjsko Petrovo Selo",
  "Barat",
  "Barban",
  "Barbarići Kravarski",
  "Barbat na Rabu",
  "Barbići",
  "Baredine",
  "Barica",
  "Barilović",
  "Barić Draga",
  "Barići",
  "Barlabaševec",
  "Barlete",
  "Barovka",
  "Bartići",
  "Bartolići",
  "Bartolovci",
  "Bartolovec",
  "Barušići",
  "Basara",
  "Bast",
  "Bastajski Brđani",
  "Bater",
  "Batina",
  "Batinjani",
  "Batinjska Rijeka",
  "Batinova Kosa",
  "Batinske",
  "Batlug",
  "Batnoga",
  "Batomalj",
  "Batrina",
  "Baturi",
  "Bazgalji",
  "Bazli",
  "Baćin",
  "Baćin Dol",
  "Baćina",
  "Bačevac",
  "Bačkovica",
  "Bačva",
  "Bađinec",
  "Bašanija",
  "Bašarinka",
  "Baška",
  "Baška Voda",
  "Baške Oštarije",
  "Baškoti",
  "Baštijani",
  "Bebrina",
  "Bedekovčina",
  "Bedenec",
  "Bedenica",
  "Bedenik",
  "Bedenička",
  "Beder",
  "Bednja",
  "Bednjica",
  "Begi",
  "Begluci",
  "Begovac",
  "Begovača",
  "Begovići",
  "Begovo Brdo",
  "Begovo Brdo Žumberačko",
  "Begovo Razdolje",
  "Beketinci",
  "Beketinec",
  "Bektež",
  "Bela",
  "Bela Vodica",
  "Belaj",
  "Belajska Vinica",
  "Belajske Poljice",
  "Belajski Malinci",
  "Belanovo Selo",
  "Belavići",
  "Belec",
  "Belej",
  "Beletinec",
  "Beli",
  "Beli Manastir",
  "Belica",
  "Belinsko Selo",
  "Belišće",
  "Beljevina",
  "Belo",
  "Belo Selo",
  "Beloslavec",
  "Belovar Zlatarski",
  "Belošići",
  "Belski Ravan",
  "Belčići",
  "Bencani",
  "Beničanci",
  "Benkovac",
  "Benkovac Fužinski",
  "Benkovačko Selo",
  "Benkovec",
  "Benkovec Petrovski",
  "Benkovo",
  "Benčani",
  "Benčići",
  "Berak",
  "Beram",
  "Beravci",
  "Berek",
  "Beretinec",
  "Berislavec",
  "Bertelovci",
  "Bertoši",
  "Bestovje",
  "Bestrma",
  "Beter",
  "Betina",
  "Bezavina",
  "Beč",
  "Bečic",
  "Bešinci",
  "Bešlinec",
  "Bibali",
  "Bibinje",
  "Bibići",
  "Bicko Selo",
  "Bijela",
  "Bijela Gorica",
  "Bijela Loza",
  "Bijela Stijena",
  "Bijele Vode",
  "Bijele Zemlje",
  "Bijeli Klanac",
  "Bijeli Vir",
  "Bijeljevina Orahovička",
  "Bijelnik",
  "Bijelo Brdo",
  "Bikovec",
  "Bila Vlaka",
  "Bilaj",
  "Bile",
  "Bili Brig",
  "Bilice",
  "Bilišane",
  "Bilje",
  "Biljevec",
  "Biljevina",
  "Biljevine",
  "Bilo",
  "Biograd na Moru",
  "Biokovsko Selo",
  "Biorine",
  "Biovičino Selo",
  "Biočić",
  "Birovača",
  "Bisag",
  "Bisko",
  "Biskupija",
  "Bistrač",
  "Bistrica",
  "Bistrinci",
  "Bitorajci",
  "Bizovac",
  "Bićine",
  "Bičići",
  "Biševo",
  "Biškupci",
  "Biškupec Zelinski",
  "Bišćanovo",
  "Bjelajci",
  "Bjelanovac",
  "Bjelina",
  "Bjeliševac",
  "Bjelkovac",
  "Bjelopolje",
  "Bjelovar",
  "Blaca",
  "Blace",
  "Blacko",
  "Blagaj",
  "Blagdanići",
  "Blagorodovac",
  "Blanje",
  "Blata",
  "Blatna Vas",
  "Blatnica",
  "Blatnica Pokupska",
  "Blato",
  "Blato na Cetini",
  "Blatuša",
  "Blaškovec",
  "Blaškovići",
  "Blaževci",
  "Blaževdol",
  "Blažević Dol",
  "Blinja",
  "Blinjska Greda",
  "Blinjski Kut",
  "Blizna Donja",
  "Blizna Gornja",
  "Bobare",
  "Bobodol",
  "Bobota",
  "Bobovac",
  "Bobovec Rozganski",
  "Bobovec Tomaševečki",
  "Bobovica",
  "Bobovišća",
  "Bobovje",
  "Bocanjevci",
  "Bockovac",
  "Bodegraj",
  "Bodovaljci",
  "Bogatić",
  "Bogatnik",
  "Bogačevo",
  "Bogačevo Riječko",
  "Bogdanovci",
  "Bogdanovec",
  "Bogdanovići",
  "Bogdašić",
  "Bogomolje",
  "Bogovci",
  "Bogovići",
  "Bogovolja",
  "Bojana",
  "Bojačno",
  "Bojna",
  "Bojnikovec",
  "Bok Palanječki",
  "Bokane",
  "Bokordići",
  "Bokšić",
  "Bokšić Lug",
  "Bol",
  "Bolfan",
  "Boljenovići",
  "Boljevići",
  "Boljun",
  "Boljunsko Polje",
  "Bolman",
  "Bolomače",
  "Bolč",
  "Bonaci",
  "Boraja",
  "Borak",
  "Borenec",
  "Borik",
  "Borinići",
  "Boričevac",
  "Boričevci",
  "Boričevec Toplički",
  "Boriš",
  "Borje",
  "Borkovec",
  "Borojevci",
  "Borojevići",
  "Borova",
  "Borova Kosa",
  "Borovac",
  "Borovci",
  "Borovik",
  "Borovita",
  "Borovljani",
  "Borovo",
  "Borut",
  "Bosanci",
  "Bosanka",
  "Bosiljevo",
  "Bosna",
  "Botinac",
  "Botinovec",
  "Botovo",
  "Bović",
  "Bočadir",
  "Bočaki",
  "Bočkinci",
  "Bočkovec",
  "Bošana",
  "Boševci",
  "Boškari",
  "Bošnjaci",
  "Bošt",
  "Božava",
  "Božjakovina",
  "Brajakovo Brdo",
  "Brajdić Selo",
  "Brajkovići",
  "Bralići",
  "Branešci",
  "Branimirovac",
  "Branjin Vrh",
  "Branjina",
  "Braslovje",
  "Bratelji",
  "Bratina",
  "Bratiškovci",
  "Bratkovec",
  "Bratovanci",
  "Bratovići",
  "Bratovski Vrh",
  "Bratulići",
  "Bratuljevci",
  "Bračak",
  "Bračevci",
  "Bračević",
  "Brašina",
  "Brašljevica",
  "Brbinj",
  "Brcković Draga",
  "Brckovljani",
  "Brdce",
  "Brdo",
  "Brdo Cirkvensko",
  "Brdo Jesenjsko",
  "Brdo Orehovečko",
  "Brdo Utinjsko",
  "Brdovec",
  "Brebornica",
  "Brebrovac",
  "Breg",
  "Breg Mokrički",
  "Bregana",
  "Bregana Pisarovinska",
  "Breganica",
  "Bregi",
  "Bregi Kostelski",
  "Bregi Radobojski",
  "Bregi Zabočki",
  "Bregovljana",
  "Brekinska",
  "Brela",
  "Bresnica",
  "Brest",
  "Brest Pokupski",
  "Brest pod Učkom",
  "Brestača",
  "Brestik",
  "Brestova Draga",
  "Brestovac",
  "Brestovačka Brda",
  "Brestovec Orehovički",
  "Breza",
  "Brezakovec",
  "Brezani",
  "Brezari",
  "Brezarić",
  "Breze",
  "Brezik",
  "Brezik Našički",
  "Brezine",
  "Brezje",
  "Brezje Dobransko",
  "Brezje Dravsko",
  "Brezje Miholečko",
  "Brezje Vivodinsko",
  "Breznica",
  "Breznica Našička",
  "Breznica Đakovačka",
  "Breznik",
  "Breznik Plešivički",
  "Breznik Žakanjski",
  "Breznički Hum",
  "Brezno Gora",
  "Brezova",
  "Brezova Glava",
  "Brezova Gora",
  "Brezovac",
  "Brezovac Dobroselski",
  "Brezovac Žumberački",
  "Brezovec",
  "Brezovec Zelinski",
  "Brezovica",
  "Brezovica ",
  "Brezovica Petrovska",
  "Brezovica Žumberačka",
  "Brezovljani",
  "Brezovljani Vojlovički",
  "Brezovo Polje",
  "Brečevići",
  "Brečići",
  "Brešca",
  "Breštane",
  "Breštanovci",
  "Brežane Lekeničke",
  "Brežani",
  "Brgat Donji",
  "Brgat Gornji",
  "Brgod",
  "Brgud",
  "Brgudac",
  "Brgulje",
  "Bribir",
  "Brig",
  "Brihovo",
  "Brijest",
  "Brijesta",
  "Briješće",
  "Brinjani",
  "Brinje",
  "Brinjeva Draga",
  "Brist",
  "Bristivica",
  "Brič",
  "Bričanci",
  "Briševo",
  "Brištane",
  "Brkač",
  "Brkiševina",
  "Brlekovo",
  "Brlenić",
  "Brlog",
  "Brlog Ozaljski",
  "Brloška Dubrava",
  "Brnaze",
  "Brnelići",
  "Brnjavac",
  "Brnjeuška",
  "Brnjica",
  "Brnobići",
  "Brnčići",
  "Broce",
  "Brod Moravice",
  "Brod na Kupi",
  "Brodarica",
  "Brodarovec",
  "Brodić",
  "Brodski Drenovac",
  "Brodski Stupnik",
  "Brodski Varoš",
  "Brodski Zdenci",
  "Broskvari",
  "Brotnice",
  "Brotnja",
  "Brovinje",
  "Broćanac",
  "Bročice",
  "Brođanci",
  "Brođani",
  "Brseč",
  "Brsečine",
  "Brtonigla",
  "Brubno",
  "Brusići",
  "Brusje",
  "Brusnik",
  "Bruvno",
  "Brušane",
  "Bruška",
  "Brzac",
  "Brčevec",
  "Brčino",
  "Brčići",
  "Brđani",
  "Brđani Cesta",
  "Brđani Kosa",
  "Brđani Sokolovački",
  "Bršadin",
  "Brštanovo",
  "Bubani",
  "Bubnjarački Brod",
  "Bubnjarci",
  "Bucalovići",
  "Budak",
  "Budakovac",
  "Budanica",
  "Budančevica",
  "Budačka Rijeka",
  "Budaševo",
  "Budim Vivodinski",
  "Budimci",
  "Budimir",
  "Budinjak",
  "Budinšćina",
  "Budinščak",
  "Budislavec",
  "Budrovac",
  "Budrovac Lukački",
  "Budrovci",
  "Buhača",
  "Buinja",
  "Buinjski Riječani",
  "Buići",
  "Bujarići",
  "Bujavica",
  "Buje",
  "Buk",
  "Buk-Vlaka",
  "Bukevje",
  "Bukori",
  "Bukov Vrh",
  "Bukova",
  "Bukovac Perjasički",
  "Bukovac Perušićki",
  "Bukovac Podvrški",
  "Bukovac Svetojanski",
  "Bukovački Antunovac",
  "Bukovec",
  "Bukovec Zelinski",
  "Bukovica",
  "Bukovica Prekriška",
  "Bukovica Utinjska",
  "Buković",
  "Bukovje Bistransko",
  "Bukovje Križevačko",
  "Bukovje Netretićko",
  "Bukovje Podvrško",
  "Bukovlje",
  "Bukovčak",
  "Bukovčani",
  "Bukvik",
  "Bulinac",
  "Bulić",
  "Bulići",
  "Bunić",
  "Bunjak",
  "Bunjani",
  "Bunjevci",
  "Burić Selo",
  "Burići",
  "Buroli",
  "Busnovi",
  "Butkovec",
  "Butkovići",
  "Butoniga",
  "Buzadovac",
  "Buzdohanj",
  "Buzet",
  "Buzeta",
  "Buzin",
  "Bučje",
  "Bučje Gorjansko",
  "Bušetina",
  "Buševec",
  "Bušević",
  "Bušin",
  "Bužim",
  "Bužinija",
  "Bzenica",
  "Cabuna",
  "Cage",
  "Caginec",
  "Cancini",
  "Careva Draga",
  "Carevdar",
  "Carevići",
  "Carevo Polje",
  "Carevo Selo",
  "Cargovec",
  "Caska",
  "Cavtat",
  "Cebovec",
  "Celine",
  "Celine Goričke",
  "Celine Samoborske",
  "Cepeliš",
  "Cepidlak",
  "Cera",
  "Cere",
  "Ceremošnjak",
  "Cerik",
  "Cerina",
  "Cerion",
  "Cerić",
  "Cerjani",
  "Cerje",
  "Cerje Jesenjsko",
  "Cerje Letovanićko",
  "Cerje Nebojse",
  "Cerje Pokupsko",
  "Cerje Samoborsko",
  "Cerje Tužno",
  "Cerje Vivodinsko",
  "Cerna",
  "Cernik",
  "Cerovac",
  "Cerovac Barilovićki",
  "Cerovac Vukmanićki",
  "Cerovački Galovići",
  "Cerovica",
  "Cerovlje",
  "Cerovnik",
  "Cerovski Vrh",
  "Cesarica",
  "Cesarska Ves",
  "Cestica",
  "Cetina",
  "Cetingrad",
  "Cetinovec",
  "Cetinski Varoš",
  "Cicvare",
  "Ciglena",
  "Ciglenica",
  "Ciglenica Zagorska",
  "Ciglenik",
  "Cigrovec",
  "Cikote",
  "Cirkovljan",
  "Cirkvena",
  "Cista Provo",
  "Cista Velika",
  "Civljane",
  "Cokuni",
  "Colnari",
  "Cremušina",
  "Cres",
  "Cret Bizovački",
  "Cret Viljevski",
  "Crevarska Strana",
  "Crikvenica",
  "Crivac",
  "Crklada",
  "Crkovec",
  "Crkvari",
  "Crkveni Bok",
  "Crkveni Vrhovci",
  "Crljenci",
  "Crljenik",
  "Crna Draga",
  "Crna Klada",
  "Crna Mlaka",
  "Crnac",
  "Crni",
  "Crni Dabar",
  "Crni Kal",
  "Crni Lazi",
  "Crni Lug",
  "Crni Potok",
  "Crno",
  "Crno Kamanje",
  "Crno Vrelo",
  "Crveni Vrh",
  "Cubinec",
  "Cugovec",
  "Cukrići",
  "Culibrki",
  "Cunj",
  "Curkovec",
  "Cvetišće",
  "Cvetkovec",
  "Cvetković",
  "Cvetković Brdo",
  "Cvetlin",
  "Cvetnić Brdo",
  "Cvijanović Brdo",
  "Cvitani",
  "Cvitići",
  "Cvitović",
  "Dabar",
  "Dabašnica",
  "Dabrina",
  "Dajla",
  "Dalj",
  "Damalj",
  "Danci",
  "Dane",
  "Dani",
  "Danilo",
  "Danilo Biranj",
  "Danilo Kraljice",
  "Dančanje",
  "Dančulovići",
  "Dapci",
  "Dapčevački Brđani",
  "Daranovci",
  "Darda",
  "Darkovac",
  "Daruvar",
  "Daruvarski Brestovac",
  "Daruvarski Vinogradi",
  "Daskatica",
  "Dautan",
  "Davor",
  "Dazlina",
  "Deanovec",
  "Deanovići",
  "Debeljak",
  "Debeljuhi",
  "Debelo Brdo",
  "Debelo Brdo I",
  "Debelo Brdo II",
  "Dedin",
  "Dedina",
  "Dekanovec",
  "Deklevi",
  "Deklešanec",
  "Deklići",
  "Dekovići",
  "Delači",
  "Delić Poljana",
  "Delići",
  "Delkovec",
  "Delnice",
  "Delovi",
  "Dereza",
  "Derežani",
  "Deringaj",
  "Dervišaga",
  "Desinić",
  "Desinić Gora",
  "Desmerice",
  "Desna Martinska Ves",
  "Desne",
  "Desni Degoj",
  "Desni Dubrovčak",
  "Desni Štefanki",
  "Desno Sredičko",
  "Desno Trebarjevo",
  "Desno Željezno",
  "Detkovac",
  "Dežanovac",
  "Deževci",
  "Dijakovac",
  "Dijaneš",
  "Dijankovec",
  "Diklići",
  "Dinjevac",
  "Dinjiška",
  "Dioš",
  "Divjake",
  "Divojevići",
  "Divoselo",
  "Divoš",
  "Divoševci",
  "Divulje",
  "Divuša",
  "Divšići",
  "Dišnik",
  "Djedina Rijeka",
  "Dnopolje",
  "Dobra Kuća",
  "Dobra Voda",
  "Dobranje",
  "Dobrenići",
  "Dobreć",
  "Dobri Zdenci",
  "Dobrinj",
  "Dobrinče",
  "Dobrić",
  "Dobrogošće",
  "Dobropoljana",
  "Dobropoljci",
  "Dobroselo",
  "Dobrovac",
  "Dobrović",
  "Dodoši",
  "Dograde",
  "Dojutrovica",
  "Dokmanovići",
  "Dol",
  "Dol Klanječki",
  "Dolac",
  "Dolanjski Jarak",
  "Dolci",
  "Dolec Podokićki",
  "Dolenci",
  "Dolenja Vas",
  "Doli",
  "Dolica",
  "Dolina",
  "Dolića Draga",
  "Doliće",
  "Doljan",
  "Doljanci",
  "Doljanec",
  "Doljani",
  "Doljani Žumberački",
  "Doljanovci",
  "Dolnjaki",
  "Dolovo",
  "Doluš",
  "Domagović",
  "Domahovo",
  "Domaji",
  "Domankuš",
  "Domaslovec",
  "Domašinec",
  "Domijanići",
  "Dominkovica",
  "Domitrovec",
  "Domovec",
  "Donja Banda",
  "Donja Batina",
  "Donja Bačuga",
  "Donja Bebrina",
  "Donja Bistra",
  "Donja Brckovčina",
  "Donja Brusovača",
  "Donja Budičina",
  "Donja Bukovica",
  "Donja Bučica",
  "Donja Dobra",
  "Donja Drenova",
  "Donja Dubrava",
  "Donja Dubrava ",
  "Donja Glina",
  "Donja Glogovnica",
  "Donja Gračenica",
  "Donja Greda",
  "Donja Jagodnja",
  "Donja Jelenska",
  "Donja Konjščina",
  "Donja Kovačica",
  "Donja Krašićevica",
  "Donja Kupčina",
  "Donja Lamana Draga",
  "Donja Letina",
  "Donja Lomnica",
  "Donja Mlinoga",
  "Donja Motičina",
  "Donja Obreška",
  "Donja Obrijež",
  "Donja Oraovica",
  "Donja Pastuša",
  "Donja Pačetina",
  "Donja Perjasica",
  "Donja Petrička",
  "Donja Pištana",
  "Donja Plemenšćina",
  "Donja Podgora",
  "Donja Poljana",
  "Donja Purgarija",
  "Donja Pušća",
  "Donja Rašenica",
  "Donja Reka",
  "Donja Rijeka",
  "Donja Selnica",
  "Donja Stranica",
  "Donja Stubica",
  "Donja Stupnica",
  "Donja Suvaja",
  "Donja Topličica",
  "Donja Trebinja",
  "Donja Trstenica",
  "Donja Velešnja",
  "Donja Velika",
  "Donja Visočka",
  "Donja Višnjica",
  "Donja Vlahinička",
  "Donja Voća",
  "Donja Vrba",
  "Donja Vrijeska",
  "Donja Vrućica",
  "Donja Zdenčina",
  "Donja Zelina",
  "Donja Čemernica",
  "Donja Šemnica",
  "Donja Šumetlica",
  "Donja Šušnjara",
  "Donja Žrvnica",
  "Donjara",
  "Donje Bazije",
  "Donje Biljane",
  "Donje Brezno",
  "Donje Bukovlje",
  "Donje Ceranje",
  "Donje Cjepidlake",
  "Donje Dubrave",
  "Donje Dvorišće",
  "Donje Gnojnice",
  "Donje Jame",
  "Donje Jesenje",
  "Donje Komarevo",
  "Donje Kusonje",
  "Donje Ladanje",
  "Donje Makojišće",
  "Donje Mekušje",
  "Donje Mokrice",
  "Donje Mrzlo Polje Mrežn.",
  "Donje Novo Selo",
  "Donje Obuljeno",
  "Donje Ogorje",
  "Donje Orešje",
  "Donje Pazarište",
  "Donje Planjane",
  "Donje Podotočje",
  "Donje Polje",
  "Donje Postinje",
  "Donje Predrijevo",
  "Donje Prekrižje",
  "Donje Prilišće",
  "Donje Primišlje",
  "Donje Psarjevo",
  "Donje Raštane",
  "Donje Selište",
  "Donje Selo",
  "Donje Sitno",
  "Donje Stative",
  "Donje Taborište",
  "Donje Tihovo",
  "Donje Utore",
  "Donje Vino",
  "Donje Vinovo",
  "Donje Vratno",
  "Donje Zagorje",
  "Donje Zdjelice",
  "Donji Andrijevci",
  "Donji Babin Potok",
  "Donji Bitelić",
  "Donji Bjelovac",
  "Donji Bogićevci",
  "Donji Borki",
  "Donji Budački",
  "Donji Bukovac Žakanjski",
  "Donji Cerovac",
  "Donji Cerovljani",
  "Donji Crnogovci",
  "Donji Daruvar",
  "Donji Desinec",
  "Donji Dobretin",
  "Donji Dolac",
  "Donji Draganec",
  "Donji Dragičevci",
  "Donji Dubovec",
  "Donji Emovci",
  "Donji Fodrovec",
  "Donji Furjan",
  "Donji Grad ",
  "Donji Grahovljani",
  "Donji Gučani",
  "Donji Hrastovac",
  "Donji Hrašćan",
  "Donji Hruševec",
  "Donji Humac",
  "Donji Jalšovec",
  "Donji Javoranj",
  "Donji Karin",
  "Donji Kašić",
  "Donji Klasnić",
  "Donji Kneginec",
  "Donji Koncovčak",
  "Donji Kosinj",
  "Donji Kraj",
  "Donji Kraljevec",
  "Donji Kremen",
  "Donji Kukuruzari",
  "Donji Kućan",
  "Donji Laduč",
  "Donji Lapac",
  "Donji Lađevac",
  "Donji Lepuri",
  "Donji Lipovac",
  "Donji Lipovčani",
  "Donji Lović",
  "Donji Ložac",
  "Donji Macelj",
  "Donji Marinkovac",
  "Donji Markovac",
  "Donji Maslarac",
  "Donji Mekinjar",
  "Donji Meljani",
  "Donji Mihaljevec",
  "Donji Miholjac",
  "Donji Miklouš",
  "Donji Mosti",
  "Donji Muć",
  "Donji Nikšić",
  "Donji Okrug",
  "Donji Oštrc",
  "Donji Oštri Vrh Ozaljski",
  "Donji Poloj",
  "Donji Popovac",
  "Donji Prnjarovec",
  "Donji Proložac",
  "Donji Pustakovec",
  "Donji Rogolji",
  "Donji Selkovac",
  "Donji Sjeničak",
  "Donji Skrad",
  "Donji Slatinik",
  "Donji Srb",
  "Donji Sređani",
  "Donji Stupnik",
  "Donji Tkalec",
  "Donji Turni",
  "Donji Vaganac",
  "Donji Varoš",
  "Donji Velemerić",
  "Donji Vidovec",
  "Donji Viduševac",
  "Donji Vinjani",
  "Donji Vukojevac",
  "Donji Vukšinac",
  "Donji Vukšići",
  "Donji Vučkovići",
  "Donji Zagon",
  "Donji Zbilj",
  "Donji Zebanec",
  "Donji Zvečaj",
  "Donji Čaglić",
  "Donji Čemehovec",
  "Donji Šajn",
  "Donji Šarampov",
  "Donji Šehovac",
  "Donji Škrnik",
  "Donji Štrbci",
  "Donji Žagari",
  "Donji Žirovac",
  "Dopsin",
  "Doričići",
  "Došen Dabar",
  "Draga",
  "Draga Bašćanska",
  "Draga Lukovdolska",
  "Draga Svetojanska",
  "Dragalić",
  "Draganić",
  "Draganje Selo",
  "Draganlug",
  "Draganovec",
  "Drage",
  "Dragljane",
  "Dragonoš",
  "Dragoslavec",
  "Dragoslavec Breg",
  "Dragoslavec Selo",
  "Dragotin",
  "Dragotina",
  "Dragotinci",
  "Dragovanščak",
  "Dragovci",
  "Dragove",
  "Dragović",
  "Dragovići",
  "Dragozetići",
  "Dragoševci",
  "Dragošička",
  "Draguzeti",
  "Draguć",
  "Drakulić Rijeka",
  "Dramalj",
  "Drastin",
  "Drače",
  "Dračevac",
  "Dračevac Ninski",
  "Dračevica",
  "Dračevo Polje",
  "Draše",
  "Draškovac",
  "Draškovec",
  "Drašković",
  "Drašnice",
  "Drašći Vrh",
  "Draž",
  "Draževitići",
  "Dražica",
  "Dražice",
  "Drenje",
  "Drenje Brdovečko",
  "Drenje Šćitarjevsko",
  "Drenov Bok",
  "Drenov Klanac",
  "Drenovac Banski",
  "Drenovac Osredački",
  "Drenovac Radučki",
  "Drenovci",
  "Drenovec",
  "Drenovica Lipnička",
  "Drežnica",
  "Drežnik",
  "Drežnik Grad",
  "Drežnik Podokićki",
  "Drinak",
  "Drinovci",
  "Drivenik",
  "Drlež",
  "Drljanovac",
  "Drljača",
  "Drnek",
  "Drniš",
  "Drnje",
  "Dropkovec",
  "Dropčevec",
  "Drum",
  "Druškovec",
  "Druškovec Gora",
  "Druškovec Humski",
  "Družbinec",
  "Družilovec",
  "Drvenik",
  "Drvenik Mali",
  "Drvenik Veliki",
  "Drškovci",
  "Držimurec",
  "Duba",
  "Duba Konavoska",
  "Duba Pelješka",
  "Duba Stonska",
  "Duboka",
  "Duboki Dol",
  "Dubovac",
  "Dubovec",
  "Dubovec Bisaški",
  "Dubovica",
  "Dubovik",
  "Dubočac",
  "Duboševica",
  "Dubranec",
  "Dubrava",
  "Dubrava Križovljanska",
  "Dubrava Pušćanska",
  "Dubrava Samoborska",
  "Dubrava Zabočka",
  "Dubrava kod Tisna",
  "Dubrava kod Šibenika",
  "Dubravci",
  "Dubrave",
  "Dubravec",
  "Dubravica",
  "Dubravica Desinićka",
  "Dubravice",
  "Dubravka",
  "Dubravski Markovac",
  "Dubravčani",
  "Dubrovnik",
  "Dubrovčan",
  "Duga Gora",
  "Duga Luka",
  "Duga Međa",
  "Duga Resa",
  "Duga Rijeka",
  "Dugače",
  "Duge Njive",
  "Dugi Dol",
  "Dugi Rat",
  "Dugnjevec",
  "Dugo Selo",
  "Dugo Selo Lasinjsko",
  "Dugo Selo Lukačko",
  "Dugobabe",
  "Dugopolje",
  "Duhovi",
  "Dujmić Selo",
  "Dujmići",
  "Dukovec",
  "Dulepska",
  "Dumače",
  "Dunave",
  "Dunjak",
  "Dunjkovec",
  "Duričići",
  "Durlinci",
  "Dusina",
  "Duzluk",
  "Duće",
  "Dučići",
  "Dužica",
  "Dvor",
  "Dvoranci",
  "Dvori",
  "Dvorište",
  "Dvorište Vivodinsko",
  "Dvorišće",
  "Dvorišće Ozaljsko",
  "Dvorjanci",
  "Dvornica",
  "Dvorska",
  "Džaperovac",
  "Eminovci",
  "Emovački Lug",
  "Ercegovci",
  "Erdelj",
  "Erdovec",
  "Erdut",
  "Erkovčići",
  "Ernestinovo",
  "Ertić",
  "Ervenik",
  "Ervenik Zlatarski",
  "Eržišće",
  "Fabci",
  "Fajerovec",
  "Falašćak",
  "Falinić Breg",
  "Farini",
  "Farkaševac",
  "Farkaševec Samoborski",
  "Fažana",
  "Fažonci",
  "Ferbežari",
  "Ferdinandovac",
  "Ferenci",
  "Ferežani",
  "Feričanci",
  "Ferketinec",
  "Ferovac",
  "Festinec",
  "Filipana",
  "Filipi",
  "Filipini",
  "Filipići",
  "Filipovac",
  "Filipovići",
  "Filozići",
  "Finida",
  "Finčevec",
  "Fiorini",
  "Flengi",
  "Florijan",
  "Fodrovec Riječki",
  "Foli",
  "Forkuševci",
  "Forčići",
  "Fotez Breg",
  "Frančići",
  "Frata",
  "Fratrovci",
  "Fratrovci Ozaljski",
  "Frkanovec",
  "Frkašić",
  "Frketić Selo",
  "Frkljevci",
  "Frkuljevec Mihovljanski",
  "Frkuljevec Peršaveški",
  "Frnjolići",
  "Fuka",
  "Furjanići",
  "Fučkovac",
  "Fuškulin",
  "Fužine",
  "Gabajeva Greda",
  "Gaber",
  "Gabonjin",
  "Gaboš",
  "Gabrili",
  "Gabrilovac",
  "Gabrovec",
  "Gage",
  "Gaj",
  "Gajac",
  "Gajana",
  "Gajine",
  "Gajić",
  "Gala",
  "Galezova Draga",
  "Galgovo",
  "Galin",
  "Galižana",
  "Galovac",
  "Galovec",
  "Galovec Začretski",
  "Galović Selo",
  "Gambetići",
  "Gamboci",
  "Garbina",
  "Gardinovec",
  "Gardun",
  "Garešnica",
  "Garešnički Brestovac",
  "Garica",
  "Garjak",
  "Garčin",
  "Gat",
  "Gata",
  "Gazije",
  "Gaćelezi",
  "Gaćeško Selo",
  "Gaćište",
  "Gačeša Selo",
  "Gačice",
  "Gašinci",
  "Gašparci",
  "Gdinj",
  "Gedići",
  "Gejkovac",
  "Generalski Stol",
  "Gerovo",
  "Gerovo Tounjsko",
  "Gerovski Kraj",
  "Gezinci",
  "Gečkovec",
  "Giletinci",
  "Gizdavac",
  "Gladovec Kravarski",
  "Gladovec Pokupski",
  "Glavace",
  "Glavani",
  "Glavat",
  "Glavica",
  "Glavice",
  "Glavina Donja",
  "Glavina Gornja",
  "Glavičani",
  "Glibodol",
  "Glina",
  "Glinice",
  "Glinska Poljana",
  "Glinsko Vrelo",
  "Gljev",
  "Globočec",
  "Globočec Ludbreški",
  "Glogovac",
  "Glogovec Zagorski",
  "Glogovica",
  "Glogovo",
  "Glušci",
  "Glušinja",
  "Gnojnice",
  "Godinjak",
  "Gojanec",
  "Gojkovac",
  "Gojlo",
  "Gola",
  "Golaš",
  "Golenić",
  "Goleši Žumberački",
  "Goli Vrh",
  "Goli Vrh Netretićki",
  "Goli Vrh Ozaljski",
  "Golik",
  "Goliki",
  "Golinci",
  "Golinja",
  "Goljak",
  "Goljak Klanječki",
  "Golo Brdo",
  "Golobrdac",
  "Golobrdci",
  "Gologorica",
  "Gologorički Dol",
  "Golovik",
  "Golubinjak",
  "Golubić",
  "Golubići",
  "Golubovac Divuški",
  "Gomirje",
  "Gondolići",
  "Gonjeva",
  "Gora",
  "Gora Glušići",
  "Gora Košnička",
  "Gora Veternička",
  "Goranec",
  "Gorani",
  "Gorači",
  "Gorenci",
  "Gorica",
  "Gorica Jamnička",
  "Gorica Lipnička",
  "Gorica Miholečka",
  "Gorica Skradska",
  "Gorica Svetojanska",
  "Gorica Valpovačka",
  "Gorice",
  "Gorinci",
  "Gorići",
  "Goričan",
  "Goričanec",
  "Goričanovec",
  "Goričica",
  "Goričice Dobranske",
  "Gorička",
  "Goričko",
  "Goriš",
  "Gorjakovo",
  "Gorjani",
  "Gorjani Sutinski",
  "Gorkovec",
  "Gorniki Vivodinski",
  "Gornja Batina",
  "Gornja Bačuga",
  "Gornja Bebrina",
  "Gornja Bistra",
  "Gornja Brckovčina",
  "Gornja Brela",
  "Gornja Brusovača",
  "Gornja Bučica",
  "Gornja Dobra",
  "Gornja Drenova",
  "Gornja Dubrava",
  "Gornja Dubrava ",
  "Gornja Garešnica",
  "Gornja Glina",
  "Gornja Glogovnica",
  "Gornja Gračenica",
  "Gornja Greda",
  "Gornja Jagodnja",
  "Gornja Jelenska",
  "Gornja Konjščina",
  "Gornja Kovačica",
  "Gornja Krašićevica",
  "Gornja Kupčina",
  "Gornja Lamana Draga",
  "Gornja Letina",
  "Gornja Lomnica",
  "Gornja Meminska",
  "Gornja Mlinoga",
  "Gornja Motičina",
  "Gornja Močila",
  "Gornja Nugla",
  "Gornja Obreška",
  "Gornja Obrijež",
  "Gornja Oraovica",
  "Gornja Pastuša",
  "Gornja Pačetina",
  "Gornja Petrička",
  "Gornja Pištana",
  "Gornja Plemenšćina",
  "Gornja Ploča",
  "Gornja Ploščica",
  "Gornja Podgora",
  "Gornja Poljana",
  "Gornja Purgarija",
  "Gornja Pušća",
  "Gornja Rašenica",
  "Gornja Reka",
  "Gornja Rijeka",
  "Gornja Selnica",
  "Gornja Stranica",
  "Gornja Stubica",
  "Gornja Stupnica",
  "Gornja Suvaja",
  "Gornja Topličica",
  "Gornja Trebinja",
  "Gornja Trnovitica",
  "Gornja Trstenica",
  "Gornja Vas",
  "Gornja Velešnja",
  "Gornja Velika",
  "Gornja Visočka",
  "Gornja Višnjica",
  "Gornja Vlahinićka",
  "Gornja Voća",
  "Gornja Vrba",
  "Gornja Vrijeska",
  "Gornja Vrućica",
  "Gornja Zdenčina",
  "Gornja Čemernica",
  "Gornja Šemnica",
  "Gornja Šumetlica",
  "Gornja Šušnjara",
  "Gornja Žrvnica",
  "Gornjaki",
  "Gornje Bazje",
  "Gornje Biljane",
  "Gornje Brezno",
  "Gornje Bukovlje",
  "Gornje Ceranje",
  "Gornje Cjepidlake",
  "Gornje Dubrave",
  "Gornje Dvorišće",
  "Gornje Gnojnice",
  "Gornje Igrane",
  "Gornje Jame",
  "Gornje Jesenje",
  "Gornje Komarevo",
  "Gornje Kusonje",
  "Gornje Ladanje",
  "Gornje Makojišće",
  "Gornje Mokrice",
  "Gornje Mrzlo Polje Mrežn.",
  "Gornje Obuljeno",
  "Gornje Ogorje",
  "Gornje Orešje",
  "Gornje Planjane",
  "Gornje Plavnice",
  "Gornje Podotočje",
  "Gornje Pokupje",
  "Gornje Postinje",
  "Gornje Predrijevo",
  "Gornje Prekrižje",
  "Gornje Prilišće",
  "Gornje Primišlje",
  "Gornje Psarjevo",
  "Gornje Raštane",
  "Gornje Selište",
  "Gornje Selo",
  "Gornje Sitno",
  "Gornje Stative",
  "Gornje Taborište",
  "Gornje Tihovo",
  "Gornje Utore",
  "Gornje Viljevo",
  "Gornje Vinovo",
  "Gornje Vratno",
  "Gornje Vrhovine",
  "Gornje Zagorje",
  "Gornje Zdelice",
  "Gornji Andrijevci",
  "Gornji Babin Potok",
  "Gornji Bitelić",
  "Gornji Bjelovac",
  "Gornji Bogićevci",
  "Gornji Borki",
  "Gornji Budački",
  "Gornji Bukovac Žakanjski",
  "Gornji Cerovac",
  "Gornji Cerovljani",
  "Gornji Crnogovci",
  "Gornji Daruvar",
  "Gornji Desinec",
  "Gornji Dobretin",
  "Gornji Dolac",
  "Gornji Draganec",
  "Gornji Dragičevci",
  "Gornji Dubovec",
  "Gornji Emovci",
  "Gornji Fodrovec",
  "Gornji Furjan",
  "Gornji Goli Vrh Lipnički",
  "Gornji Grad- Medvešćak ",
  "Gornji Grahovljani",
  "Gornji Gučani",
  "Gornji Hrastovac",
  "Gornji Hrašćan",
  "Gornji Hruševec",
  "Gornji Humac",
  "Gornji Jalšovec",
  "Gornji Javoranj",
  "Gornji Karin",
  "Gornji Klasnić",
  "Gornji Kneginec",
  "Gornji Koncovčak",
  "Gornji Kosinj",
  "Gornji Kraljevec",
  "Gornji Kremen",
  "Gornji Kukuruzari",
  "Gornji Kuršanec",
  "Gornji Kuti",
  "Gornji Kućan",
  "Gornji Laduč",
  "Gornji Lapac",
  "Gornji Lađevac",
  "Gornji Lipovac",
  "Gornji Lipovčani",
  "Gornji Lović",
  "Gornji Ložac",
  "Gornji Macelj",
  "Gornji Marinkovac",
  "Gornji Martijanec",
  "Gornji Maslarac",
  "Gornji Meljani",
  "Gornji Mihaljevec",
  "Gornji Miholjac",
  "Gornji Miklouš",
  "Gornji Mosti",
  "Gornji Muć",
  "Gornji Nikšić",
  "Gornji Okrug",
  "Gornji Oštrc",
  "Gornji Oštri Vrh Ozaljski",
  "Gornji Poloj",
  "Gornji Popovac",
  "Gornji Prnjarovec",
  "Gornji Proložac",
  "Gornji Rogolji",
  "Gornji Selkovac",
  "Gornji Sjeničak",
  "Gornji Skrad",
  "Gornji Slatinik",
  "Gornji Srb",
  "Gornji Sređani",
  "Gornji Stupnik",
  "Gornji Tkalec",
  "Gornji Tomaš",
  "Gornji Turni",
  "Gornji Uljanik",
  "Gornji Vaganac",
  "Gornji Varoš",
  "Gornji Velemerić",
  "Gornji Viduševac",
  "Gornji Vinjani",
  "Gornji Vinkovec",
  "Gornji Vrhovci",
  "Gornji Vukojevac",
  "Gornji Vukšinac",
  "Gornji Vukšići",
  "Gornji Vučkovići",
  "Gornji Zagon",
  "Gornji Zbilj",
  "Gornji Zebanec",
  "Gornji Zvečaj",
  "Gornji Čaglić",
  "Gornji Čemehovec",
  "Gornji Šajn",
  "Gornji Šehovac",
  "Gornji Škrnik",
  "Gornji Štrbci",
  "Gornji Žagari",
  "Gornji Žirovac",
  "Goruševnjak",
  "Goršeti",
  "Goršćaki",
  "Goršćaki Ozaljski",
  "Gospić",
  "Gostenje",
  "Gostinjac",
  "Gostović",
  "Gotalovec",
  "Gotalovo",
  "Goveđari",
  "Goveđe Polje",
  "Gošić",
  "Grab",
  "Grabarak",
  "Grabarje",
  "Grabarska",
  "Grabe",
  "Graberanec",
  "Graberec",
  "Graberje",
  "Graberje Ivanićko",
  "Graberšćak",
  "Grabik",
  "Grabić",
  "Grabovac",
  "Grabovac Banski",
  "Grabovac Krnjački",
  "Grabovac Vojnićki",
  "Grabovci",
  "Grabovica",
  "Grabovnica",
  "Grabovo",
  "Graboštani",
  "Grabrić",
  "Grabričina",
  "Grabrk",
  "Grabrov Potok",
  "Grabrova",
  "Grabrovec",
  "Grabrovnica",
  "Grabrovnik",
  "Grabušić",
  "Gradac",
  "Gradac Našički",
  "Gradec",
  "Gradec Pokupski",
  "Gradečki Pavlovec",
  "Gradina",
  "Gradina Korenička",
  "Gradinje",
  "Gradići",
  "Gradište",
  "Gradišće",
  "Gradiščak",
  "Gradna",
  "Gradski Vrhovci",
  "Gradusa Posavska",
  "Gramalj",
  "Grana",
  "Grandić Breg",
  "Grandići",
  "Granice",
  "Granje",
  "Gračac",
  "Gračac Slavetićki",
  "Gračanica Šišinečka",
  "Gračec",
  "Gračina",
  "Gračišće",
  "Grbajel",
  "Grbavac",
  "Grbaševec",
  "Grdak",
  "Grdanjci",
  "Grdenci",
  "Grdoselo",
  "Grdun",
  "Grebaštica",
  "Greda",
  "Greda Breška",
  "Greda Sunjska",
  "Gredenec",
  "Gredice",
  "Gregurić Breg",
  "Gregurovec",
  "Gređani",
  "Grešćevina",
  "Grganjica",
  "Grgetići",
  "Grginac",
  "Grgurevići",
  "Grimalda",
  "Grič",
  "Griče",
  "Grižane-Belgrad",
  "Grižići",
  "Grkaveščak",
  "Grkine",
  "Grletinec",
  "Grmov",
  "Grmušani",
  "Grobnik",
  "Grohot",
  "Grohote",
  "Gromača",
  "Gromačnik",
  "Gromin Dolac",
  "Grožnjan",
  "Grtovec",
  "Grubine",
  "Grubići",
  "Grubišno Polje",
  "Gruda",
  "Grudnjak",
  "Gršćaki",
  "Gržini",
  "Gubavčevo Polje",
  "Gubaševo",
  "Guci Draganički",
  "Gudalji",
  "Gudci",
  "Gudovac",
  "Gundinci",
  "Gunja",
  "Gunjavci",
  "Gusakovec",
  "Gustelnica",
  "Gusti Laz",
  "Gustirna",
  "Guče Selo",
  "Gušće",
  "Guščerovec",
  "Gvozd",
  "Gvozdanska",
  "Gvozdansko",
  "Habjanovac",
  "Habjanovci",
  "Haganj",
  "Hajdine",
  "Hajtić",
  "Hambarište",
  "Hampovica",
  "Harina Žlaka",
  "Harkanovci",
  "Harmica",
  "Hartje",
  "Hađer",
  "Heki",
  "Hemuševec",
  "Heraki",
  "Hercegovac",
  "Herešin",
  "Hižakovec",
  "Hižanovec",
  "Hlapa",
  "Hlapičina",
  "Hlebine",
  "Hlevci",
  "Hlevnica",
  "Hodilje",
  "Hodinci",
  "Hodošan",
  "Homer",
  "Homoljac",
  "Horvatsko",
  "Hosnik",
  "Hotnja",
  "Hrastelnica",
  "Hrastin",
  "Hrastina",
  "Hrastina Samoborska",
  "Hrastje",
  "Hrastje Plešivičko",
  "Hrastovac",
  "Hrastovec Toplički",
  "Hrastovica",
  "Hrastovica Vivodinska",
  "Hrastovljan",
  "Hrastovsko",
  "Hrašća",
  "Hrašćica",
  "Hrašćina",
  "Hrboki",
  "Hrebine",
  "Hrebinec",
  "Hreljin",
  "Hreljin Ogulinski",
  "Hreljići",
  "Hrgovec",
  "Hrib",
  "Hribac",
  "Hrkanovci Đakovački",
  "Hrnjanec",
  "Hrnjevac",
  "Hromec",
  "Hrsina",
  "Hrsovo",
  "Hrtić",
  "Hruševec",
  "Hruševec Kupljenski",
  "Hruševec Pušćanski",
  "Hruškovec",
  "Hruškovica",
  "Hrušćica",
  "Hrvace",
  "Hrvatska Dubica",
  "Hrvatska Kostajnica",
  "Hrvatski Čuntić",
  "Hrvatsko",
  "Hrvatsko Polje",
  "Hrvatsko Selo",
  "Hrvatsko Žarište",
  "Hršak Breg",
  "Hršćevani",
  "Hrženica",
  "Hrženik",
  "Hudovljani",
  "Hudovo",
  "Hum",
  "Hum Bistrički",
  "Hum Košnički",
  "Hum Stubički",
  "Hum Varoš",
  "Hum Zabočki",
  "Hum na Sutli",
  "Humac",
  "Humljani",
  "Husain",
  "Husinec",
  "Husje",
  "Hutin",
  "Hvar",
  "Igrane",
  "Igrišće",
  "Ika",
  "Ilača",
  "Ilmin Dvor",
  "Ilok",
  "Ilova",
  "Ilovac",
  "Ilovačak",
  "Ilovik",
  "Ilovski Klokočevac",
  "Imbriovec",
  "Imbriovec Jalžabetski",
  "Imotica",
  "Imotski",
  "Imrijevci",
  "Imsovac",
  "Ipši",
  "Irinovac",
  "Islam Grčki",
  "Islam Latinski",
  "Ist",
  "Istočni Trojvrh",
  "Ivan Dolac",
  "Ivanbegovina",
  "Ivanbrijeg",
  "Ivandol",
  "Ivanec",
  "Ivanec Bistranski",
  "Ivanec Križevački",
  "Ivanečka Željeznica",
  "Ivanečki Vrhovec",
  "Ivanečko Naselje",
  "Ivanić Desinićki",
  "Ivanić Košnički",
  "Ivanić Miljanski",
  "Ivanić-Grad",
  "Ivanje",
  "Ivanjski Bok",
  "Ivanković Selo",
  "Ivankovo",
  "Ivanovac",
  "Ivanovci",
  "Ivanovci Gorjanski",
  "Ivanovec",
  "Ivanovo",
  "Ivanovo Polje",
  "Ivanovo Selo",
  "Ivanska",
  "Ivančani",
  "Ivančec",
  "Ivančići",
  "Ivančići Pokupski",
  "Ivići",
  "Ivoševci",
  "Ivošević Selo",
  "Izimje",
  "Ićevo",
  "Ičići",
  "Iševnica",
  "Jablan",
  "Jablanac",
  "Jablanovec",
  "Jabuka",
  "Jabukovac",
  "Jabučeta",
  "Jadranovo",
  "Jadreški",
  "Jadrići",
  "Jadrtovac",
  "Jadruhi",
  "Jagma",
  "Jagnjedovec",
  "Jagnjić Dol",
  "Jagodnjak",
  "Jagodnje",
  "Jagodno",
  "Jagrovac",
  "Jaguplije",
  "Jakačina Mala",
  "Jakačići",
  "Jakići Dolinji",
  "Jakići Gorinji",
  "Jakomići",
  "Jakopovac",
  "Jakopovec",
  "Jakov Polje",
  "Jakovci",
  "Jakovci Netretićki",
  "Jakovici",
  "Jakovlje",
  "Jakuševec Zabočki",
  "Jakšinec",
  "Jakšić",
  "Jakšići",
  "Jales Breznički",
  "Jalkovec",
  "Jalševec Breški",
  "Jalševec Nartski",
  "Jalševec Svibovečki",
  "Jalšje",
  "Jalšovec",
  "Jalžabet",
  "Jamarica",
  "Jamarje",
  "Jame",
  "Jamnica Pisarovinska",
  "Jamno",
  "Janja Gora",
  "Janja Lipa",
  "Janjina",
  "Jankovac",
  "Jankovo Selište",
  "Jančani",
  "Japaga",
  "Jarebinjak",
  "Jarek Bisaški",
  "Jarek Habekov",
  "Jarki",
  "Jarki Horvatićevi",
  "Jarmina",
  "Jarnevići",
  "Jaruge",
  "Jarušje",
  "Jarčani",
  "Jarče Polje",
  "Jasenak",
  "Jasenaš",
  "Jasenice",
  "Jasenik",
  "Jasenovac",
  "Jasenovac Zagorski",
  "Jasenovica",
  "Jasenovčani",
  "Jasensko",
  "Jasik",
  "Jasikovac",
  "Jasnić Brdo",
  "Jastrebarsko",
  "Javnica",
  "Javor",
  "Javorek",
  "Javorje",
  "Javornik",
  "Javorovac",
  "Jazavica",
  "Jazbina",
  "Jazbina Cvetlinska",
  "Jazbina Višnjička",
  "Jazvenik",
  "Jazvine",
  "Jaškovo",
  "Jeduševac",
  "Jehnići",
  "Jelenjak",
  "Jelenje",
  "Jelenščak",
  "Jelisavac",
  "Jelov Klanac",
  "Jelovec",
  "Jelovec Voćanski",
  "Jelovice",
  "Jelsa",
  "Jeminovac",
  "Jerebić",
  "Jerovec",
  "Jertovec",
  "Jesenice",
  "Jesenovik",
  "Jezera",
  "Jezerane",
  "Jezerce",
  "Jezerine",
  "Jezerišće",
  "Jezernice",
  "Jezero",
  "Jezero I Dio",
  "Jezero Klanječko",
  "Jezero Posavsko",
  "Ježenj",
  "Ježevik",
  "Ježević",
  "Ježevo",
  "Ježovec",
  "Johi",
  "Johovec",
  "Johovo",
  "Josipdol",
  "Josipovac",
  "Josipovac Punitovački",
  "Josipovo",
  "Jovac",
  "Jovići",
  "Jošan",
  "Jošavica",
  "Joševica",
  "Jugovac",
  "Jugovo Polje",
  "Juradi",
  "Jural",
  "Jurandvor",
  "Juranšćina",
  "Jurazini",
  "Jurcani",
  "Jurdani",
  "Jurga",
  "Juricani",
  "Jurićev Kal",
  "Jurići",
  "Juričići",
  "Jurjevac Punitovački",
  "Jurjevec",
  "Jurjevčani",
  "Jurketinec",
  "Jurkovac",
  "Jurkovo Selo",
  "Jurovec",
  "Jurovo",
  "Jurovski Brod",
  "Jurovčak",
  "Juršići",
  "Jušići",
  "Kabal",
  "Kablar",
  "Kadanovci",
  "Kadina Glavica",
  "Kadumi",
  "Kajgana",
  "Kajini",
  "Kakanj",
  "Kakinac",
  "Kakma",
  "Kalac",
  "Kaldanija",
  "Kaldir",
  "Kaldrma",
  "Kalebovac",
  "Kali",
  "Kalinić",
  "Kalinje",
  "Kalinovac",
  "Kalinovača",
  "Kalinovica",
  "Kalić",
  "Kalje",
  "Kalnik",
  "Kaluđerovac",
  "Kamanje",
  "Kamen",
  "Kamena Gorica",
  "Kamenac",
  "Kamenci",
  "Kamenica",
  "Kamenica Skradnička",
  "Kamenički Vrhovec",
  "Kameničko Podgorje",
  "Kamenmost",
  "Kamenska",
  "Kamenski Hrib",
  "Kamenski Vučjak",
  "Kamenski Šeovci",
  "Kamensko",
  "Kamešnica",
  "Kampelje",
  "Kampor",
  "Kanegra",
  "Kanfanar",
  "Kaniška Iva",
  "Kaniža",
  "Kaniža Gospićka",
  "Kanjane",
  "Kantrovci",
  "Kaočine",
  "Kapela",
  "Kapela Dvor",
  "Kapela Kalnička",
  "Kapela Korenička",
  "Kapela Podravska",
  "Kapela Ravenska",
  "Kapelec",
  "Kapelica",
  "Kapelna",
  "Kapelski Vrh",
  "Kapelščak",
  "Kapetanovo Polje",
  "Kapinci",
  "Kapljuv",
  "Kapovići",
  "Kaprije",
  "Kaptol",
  "Karadžićevo",
  "Karakašica",
  "Karalić",
  "Karanac",
  "Karane",
  "Karasi",
  "Karigador",
  "Karivaroš",
  "Karlobag",
  "Karlovac",
  "Karlovac Feričanački",
  "Karlovec Ludbreški",
  "Karlovići",
  "Karojba",
  "Kartalije",
  "Kastav",
  "Kasuni",
  "Katinac",
  "Katinka",
  "Katinovac",
  "Katoličke Čaire",
  "Katoličko Selišće",
  "Katoro",
  "Katun",
  "Katuni",
  "Kavran",
  "Kavrani",
  "Kačkovec",
  "Kašić",
  "Kašljavac",
  "Kašt",
  "Kaštel",
  "Kaštel Dežanovački",
  "Kaštel Gomilica",
  "Kaštel Kambelovac",
  "Kaštel Lukšić",
  "Kaštel Novi",
  "Kaštel Stari",
  "Kaštel Sućurac",
  "Kaštel Štafilić",
  "Kaštel Žegarski",
  "Kaštelanec",
  "Kaštelir",
  "Kašćerga",
  "Kebel",
  "Kegljevac",
  "Keići",
  "Kelemen",
  "Keleminovec",
  "Kelešinka",
  "Kenđelovec",
  "Kepčije",
  "Kerestinec",
  "Keseri",
  "Keserov Potok",
  "Kestenak",
  "Kestenje",
  "Kestenovac",
  "Kešinci",
  "Kihalac",
  "Kijani",
  "Kijevo",
  "Kik",
  "Kindrovo",
  "Kinjačka",
  "Kip",
  "Kirin",
  "Kirmenjak",
  "Kistanje",
  "Kitišanci",
  "Klada",
  "Kladare",
  "Kladari",
  "Kladešćica",
  "Kladje",
  "Kladnik",
  "Kladnjice",
  "Klakar",
  "Klake",
  "Klana",
  "Klanac",
  "Klanac Perjasički",
  "Klanice",
  "Klanjec",
  "Klanječno",
  "Klarići",
  "Klašnjica",
  "Klek",
  "Klenice",
  "Klenovac",
  "Klenovec Humski",
  "Klenovica",
  "Klenovnik",
  "Klenovšćak",
  "Klepeće Selo",
  "Kletište",
  "Klimen",
  "Klimni",
  "Klimno",
  "Klinac",
  "Klinča Sela",
  "Klipino Brdo",
  "Klis",
  "Klisa",
  "Kliševo",
  "Kljaić Brdo",
  "Kljake",
  "Kljenak",
  "Ključ",
  "Ključ Brdovečki",
  "Ključar",
  "Ključić Brdo",
  "Klobučak",
  "Klokovec",
  "Klokoč",
  "Klokočevac",
  "Klokočevci",
  "Klokočevec Samoborski",
  "Klokočevik",
  "Kloštar",
  "Kloštar Ivanić",
  "Kloštar Podravski",
  "Kloštar Vojakovački",
  "Klupci",
  "Klupci Začretski",
  "Klupica",
  "Kmeti",
  "Knapić",
  "Knapići",
  "Knez Gorica",
  "Knezovec",
  "Knezovljani",
  "Knežci",
  "Kneževac",
  "Kneževi Vinogradi",
  "Knežević Kosa",
  "Kneževo",
  "Knežica",
  "Knin",
  "Kninsko Polje",
  "Kobasičari",
  "Kobilić",
  "Kobilić Pokupski",
  "Kobiljak",
  "Kobiljača",
  "Kocijani",
  "Kohanjac",
  "Kokinac",
  "Kokirevo",
  "Kokorići",
  "Kokočak",
  "Kolan",
  "Kolanjski Gajac",
  "Kolarec",
  "Kolarevo Selo",
  "Kolarina",
  "Kolarić",
  "Kolarovec",
  "Kolašac",
  "Koledinec",
  "Kolenica",
  "Kolenovac",
  "Koljane",
  "Koločep",
  "Kolumbera",
  "Kom",
  "Komaji",
  "Komarna",
  "Komarnica",
  "Komarnica Ludbreška",
  "Komarovci",
  "Komatnica",
  "Komazeci",
  "Komesarac",
  "Kometnik-Jorgići",
  "Kometnik-Zubići",
  "Komin",
  "Komić",
  "Komiža",
  "Komlenići",
  "Komletinci",
  "Komogovina",
  "Komolac",
  "Komor Začretski",
  "Komora",
  "Komorica",
  "Kompanj",
  "Kompator",
  "Kompolje",
  "Kompolje Koreničko",
  "Komuševac",
  "Komušina",
  "Konak",
  "Kondrić",
  "Konjarić Vrh",
  "Konjevrate",
  "Konjkovsko",
  "Konjsko",
  "Konjsko Brdo",
  "Konjščina",
  "Kontešići",
  "Končanica",
  "Končarev Kraj",
  "Konšćani",
  "Konšćica",
  "Kopačevo",
  "Koprivna",
  "Koprivnica",
  "Koprivnica Zagorska",
  "Koprivnički Bregi",
  "Koprivnički Ivanec",
  "Koprivno",
  "Koprno",
  "Kopčevec",
  "Korana",
  "Koranska Strana",
  "Koranski Brijeg",
  "Koranski Lug",
  "Koransko Selo",
  "Kordići Žumberački",
  "Kordunski Ljeskovac",
  "Korduševci",
  "Korelići",
  "Korenica",
  "Korenić Brdo",
  "Korenići",
  "Koreničani",
  "Korenjak",
  "Koretinec",
  "Korija",
  "Korita",
  "Koritinja",
  "Koritna",
  "Korlat",
  "Korlevići",
  "Kornati",
  "Kornić",
  "Korog",
  "Koromani",
  "Koromačno",
  "Korušce",
  "Korčula",
  "Kosa",
  "Kosa Janjačka",
  "Kosi",
  "Kosijer Selo",
  "Kosijersko Selo",
  "Kosinožići",
  "Kosna",
  "Kosore",
  "Kosoriga",
  "Kosovac",
  "Kosovečko",
  "Kostadinovac",
  "Kostanj",
  "Kostanje",
  "Kostanjevac",
  "Kostanjevec Podvrški",
  "Kostanjevec Riječki",
  "Kostanjica",
  "Kostel",
  "Kostel Pribićki",
  "Kostelsko",
  "Kostrena Sveta Barbara",
  "Kostrena Sveta Lucija",
  "Kostreši Bjelovački",
  "Kostreši Šaški",
  "Kostrići",
  "Kostrčani",
  "Kotarani",
  "Kotari",
  "Kotarice",
  "Kotezi",
  "Kotlenice",
  "Kotli",
  "Kotlina",
  "Kotoriba",
  "Koturić",
  "Kovačevac",
  "Kovači",
  "Kovačić",
  "Kozalj Vrh",
  "Kozaperovica",
  "Kozarac",
  "Kozarevac",
  "Kozarevac Račanski",
  "Kozarica",
  "Kozarice",
  "Kozibrod",
  "Kozica",
  "Kozice",
  "Kozinščak",
  "Kozjak",
  "Kozjak Začretski",
  "Kozjan",
  "Kozjača",
  "Kozji Vrh",
  "Kozlikovo",
  "Kočići",
  "Kočičin",
  "Koška",
  "Koškovec",
  "Košljun",
  "Košnica",
  "Košutarica",
  "Košute",
  "Košutići",
  "Kožino",
  "Kožljak",
  "Kožljani",
  "Kožlovac",
  "Kraguj",
  "Kraj",
  "Kraj Donji",
  "Kraj Drage",
  "Kraj Gornji",
  "Krajačići",
  "Krajcar Breg",
  "Krajići",
  "Krajiška Kutinica",
  "Krajna",
  "Krajska Ves",
  "Kraljev Vrh",
  "Kraljeva Velika",
  "Kraljevac",
  "Kraljevec Radobojski",
  "Kraljevec na Sutli",
  "Kraljevec Šemnički",
  "Kraljevica",
  "Kraljevo Selo",
  "Kraljevčani",
  "Kranjci",
  "Kranjčići",
  "Krančići",
  "Krapan",
  "Krapanj",
  "Krapina",
  "Krapina Selo",
  "Krapinica",
  "Krapinske Toplice",
  "Krapje",
  "Kras",
  "Krasica",
  "Krasković",
  "Krasno Polje",
  "Kratečko",
  "Kravarsko",
  "Kravljak",
  "Kračevec",
  "Krašić",
  "Krbava",
  "Krbavica",
  "Krbavčići",
  "Krbune",
  "Krculi",
  "Kremena",
  "Kremenići",
  "Kresini",
  "Krečaves",
  "Kreševo",
  "Kreštelovac",
  "Kringa",
  "Krivac",
  "Krivaj",
  "Krivaj Sunjski",
  "Krivaja",
  "Krivaja Pustara",
  "Krivaja Vojnićka",
  "Krivi Put",
  "Krivodol",
  "Kričke",
  "Krišci",
  "Krištanovec",
  "Križ",
  "Križ Gornji",
  "Križ Hrastovački",
  "Križ Kamenica",
  "Križ Koranski",
  "Križanci",
  "Križanec",
  "Križanče",
  "Križevci",
  "Križevčec",
  "Križic",
  "Križine",
  "Križišće",
  "Križnica",
  "Križovec",
  "Križovljan",
  "Križovljan Radovečki",
  "Križpolje",
  "Krk",
  "Krkanec",
  "Krkač",
  "Krković",
  "Krkuž",
  "Krmed",
  "Krmpotske Vodice",
  "Krndija",
  "Krneza",
  "Krnežići",
  "Krnica",
  "Krničari",
  "Krnjak",
  "Krnjeuve",
  "Kropinjak",
  "Krstatice",
  "Krstinja",
  "Krti",
  "Kruge",
  "Krunoslavje",
  "Krunčići",
  "Krupa",
  "Krupače",
  "Kručica",
  "Kruševica",
  "Kruševo",
  "Kruškovac",
  "Kruškovača",
  "Krušljevec",
  "Krušljevo Selo",
  "Krušvar",
  "Krušvari",
  "Kruščica",
  "Krvavac",
  "Krvavac II",
  "Krvavica",
  "Krč",
  "Krč Bosiljevski",
  "Krčenik",
  "Krčevina",
  "Krš",
  "Kršan",
  "Kršanci",
  "Kršete",
  "Kršikla",
  "Kršinci",
  "Kršuli",
  "Krščenovec",
  "Kuberton",
  "Kujići",
  "Kujnik",
  "Kuk",
  "Kukci",
  "Kukljica",
  "Kukljić",
  "Kukuljani",
  "Kukuljanovo",
  "Kukunjevac",
  "Kukurini",
  "Kula",
  "Kula Atlagić",
  "Kula Norinska",
  "Kuljaji",
  "Kuljani",
  "Kumrovec",
  "Kuna Konavoska",
  "Kuna Pelješka",
  "Kunić",
  "Kunići Ribnički",
  "Kunišinci",
  "Kunj",
  "Kunovac Kupirovački",
  "Kunovci",
  "Kunovec",
  "Kunovec Breg",
  "Kunčani",
  "Kunđevac",
  "Kunđevec",
  "Kupa",
  "Kupari",
  "Kupeć Dol",
  "Kupina",
  "Kupinec",
  "Kupinovac",
  "Kupirovo",
  "Kupjak",
  "Kupljenovo",
  "Kupljensko",
  "Kupčina Žumberačka",
  "Kurili",
  "Kurjak",
  "Kurjavići",
  "Kurpezova Gorica",
  "Kuršanec",
  "Kusaja",
  "Kusanovec",
  "Kusijevec",
  "Kusonje",
  "Kustići",
  "Kutanja",
  "Kuterevo",
  "Kutina",
  "Kutinica",
  "Kutinska Slatina",
  "Kutjevo",
  "Kutnjak",
  "Kutovi",
  "Kuzma",
  "Kuzma Perjasička",
  "Kuzmica",
  "Kuzminec",
  "Kuzminec Miljanski",
  "Kućan Ludbreški",
  "Kućan Marof",
  "Kućanci",
  "Kućanci Đakovački",
  "Kućari",
  "Kućibreg",
  "Kućišta Cesarička",
  "Kučari",
  "Kuče",
  "Kučeli",
  "Kučer",
  "Kučevice",
  "Kučine",
  "Kučiće",
  "Kučište",
  "Kuševac",
  "Kuštani",
  "Kuželj",
  "Kvarte",
  "Labin",
  "Labinci",
  "Lacići",
  "Ladešići",
  "Ladimirevci",
  "Ladina",
  "Ladinec",
  "Ladislav",
  "Ladislav Sokolovački",
  "Ladislavec",
  "Ladići",
  "Ladrovići",
  "Ladvenjak",
  "Laginji",
  "Lakmartin",
  "Lakovići",
  "Laktac",
  "Laktec",
  "Lakušija",
  "Lalići",
  "Laminac",
  "Lanišće",
  "Lančić",
  "Lapat",
  "Lapovac",
  "Lapovci",
  "Lapšina",
  "Lasinja",
  "Laslavići",
  "Laslovo",
  "Lasovac",
  "Lasovac Brdo",
  "Lastine",
  "Lastovo",
  "Latin",
  "Latinovac",
  "Lautari",
  "Laz Bistrički",
  "Laz Stubički",
  "Lazac Lokvarski",
  "Lazarići",
  "Laze",
  "Laze Prnjavor",
  "Lazi",
  "Lazi Krapinski",
  "Lazi Turopoljski",
  "Lazina Čička",
  "Lađanska",
  "Lađevac",
  "Lađevačko Selište",
  "Lađevci",
  "Lašići",
  "Ledenice",
  "Ledenik",
  "Ledenik Cesarički",
  "Ledina",
  "Ledine Klanječke",
  "Ledinec",
  "Legovići",
  "Legrad",
  "Lekenik",
  "Lekneno",
  "Lemeš",
  "Lemeš Križevački",
  "Lenišće",
  "Lepa Ves",
  "Lepajci",
  "Lepavina",
  "Lepenica",
  "Lepoglava",
  "Lepoglavec",
  "Leprovica",
  "Lepšić",
  "Lesci",
  "Lesišćina",
  "Leska",
  "Leskova Draga",
  "Leskovac Barilovićki",
  "Leskovec",
  "Leskovec Toplički",
  "Letaj",
  "Letajac",
  "Letinac",
  "Letičani",
  "Letovanci",
  "Letovanić",
  "Letovčan Novodvorski",
  "Letovčan Tomaševečki",
  "Levanjska Varoš",
  "Levinovac",
  "Levkušje",
  "Lećevica",
  "Leštakovec",
  "Liganj",
  "Lijeva Luka",
  "Lijeva Martinska Ves",
  "Lijevi Degoj",
  "Lijevi Dubrovčak",
  "Lijevi Štefanki",
  "Lijevo Sredičko",
  "Lijevo Trebarjevo",
  "Lijevo Željezno",
  "Liješće",
  "Lila",
  "Linardići",
  "Lindar",
  "Lipa",
  "Lipice",
  "Lipik",
  "Lipine",
  "Lipje",
  "Liplje",
  "Lipnica",
  "Lipnica Zagorska",
  "Lipnik",
  "Lipov Pesak",
  "Lipovac",
  "Lipovac Hrastinski",
  "Lipovac Krstinjski",
  "Lipovac Majur",
  "Lipovača",
  "Lipovec",
  "Lipovec Lonjski",
  "Lipovljani",
  "Lipovlje",
  "Lipovnik",
  "Lipovo Brdo",
  "Lipovo Polje",
  "Lipovčani",
  "Lipovšćaki",
  "Lisac",
  "Lisine",
  "Lisičina Gorica",
  "Lisičine",
  "Lisičić",
  "Liska",
  "Livade",
  "Livana",
  "Livađani",
  "Lič",
  "Lička Jesenica",
  "Lički Novi",
  "Lički Osik",
  "Lički Ribnik",
  "Lički Čitluk",
  "Ličko Cerje",
  "Ličko Lešće",
  "Ličko Novo Selo",
  "Ličko Petrovo Selo",
  "Lišane Ostrovičke",
  "Lišane Tinjske",
  "Lišnica",
  "Lišnjak",
  "Ližnjan",
  "Ljeskovac",
  "Lještani",
  "Ljubač",
  "Ljubelj",
  "Ljubelj Kalnički",
  "Ljubešćica",
  "Ljubina",
  "Ljubitovica",
  "Ljubljanica",
  "Ljubostinje",
  "Ljubotić",
  "Ljubošina",
  "Ljudevit Selo",
  "Ljupina",
  "Ljut",
  "Ljuta",
  "Ljutići",
  "Lobor",
  "Loborika",
  "Lokošin Dol",
  "Lokva Rogoznica",
  "Lokve",
  "Lokvica",
  "Lokvičići",
  "Londžica",
  "Lonja",
  "Lonjgari",
  "Lonjica",
  "Lončar Brdo",
  "Lončarica",
  "Lopatinec",
  "Lopača",
  "Lopud",
  "Loskunja",
  "Lotine",
  "Lovas",
  "Lovinac",
  "Lović Prekriški",
  "Lovište",
  "Lovorje",
  "Lovorno",
  "Lovran",
  "Lovranska Draga",
  "Lovrentovec",
  "Lovreć",
  "Lovreća Sela",
  "Lovrečan",
  "Lovrečica",
  "Lovrečka Varoš",
  "Lovrečka Velika",
  "Lovrin",
  "Lovska",
  "Lovča",
  "Lovčić",
  "Lozan",
  "Lozari",
  "Lozica",
  "Loznati",
  "Lozovac",
  "Ložišća",
  "Ložnice",
  "Lubarska",
  "Lubena",
  "Lubenice",
  "Ludbreg",
  "Ludbreški Ivanac",
  "Ludinica",
  "Ludvinci",
  "Lug",
  "Lug Gradinski",
  "Lug Orehovički",
  "Lug Poznanovečki",
  "Lug Samoborski",
  "Lug Subotički",
  "Lug Zabočki",
  "Lugarski Breg",
  "Luka",
  "Luka Krmpotska",
  "Luka Ludbreška",
  "Luka Pokupska",
  "Lukar",
  "Lukarišće",
  "Lukavac",
  "Lukavec",
  "Lukavec Klanječki",
  "Lukavec Posavski",
  "Lukavec Sutlanski",
  "Lukač",
  "Lukačevec",
  "Lukačevec Toplički",
  "Luke",
  "Luke Poljanske",
  "Lukeži",
  "Lukinić Brdo",
  "Lukoran",
  "Lukovdol",
  "Lukovec",
  "Lukovo",
  "Lukovo Šugarje",
  "Lukovčak",
  "Lukunić Draga",
  "Lukšići Ozaljski",
  "Lumbarda",
  "Lumbardenik",
  "Lun",
  "Lunjkovec",
  "Lupinjak",
  "Lupoglav",
  "Luč",
  "Lučane",
  "Lučelnica",
  "Lučelnica Tomaševečka",
  "Lučica",
  "Lučice",
  "Lučina",
  "Lučinci",
  "Luščani",
  "Lužan Biškupečki",
  "Lužani",
  "Lužani Zagorski",
  "Lužnica",
  "Macinec",
  "Macute",
  "Madaraševec",
  "Madžarevo",
  "Madžari",
  "Magadenovac",
  "Magadinovac",
  "Magić Mala",
  "Maglenča",
  "Mahićno",
  "Mahovo",
  "Maja",
  "Majar",
  "Majdan",
  "Majer",
  "Majerje",
  "Majkovec",
  "Majkovi",
  "Majkusi",
  "Majske Poljane",
  "Majski Trtnik",
  "Majur",
  "Majurec",
  "Majške Međe",
  "Makarska",
  "Maklen",
  "Makloševac",
  "Makov Hrib",
  "Makovci",
  "Makoše",
  "Maksimir ",
  "Mala Babina Gora",
  "Mala Barna",
  "Mala Branjska",
  "Mala Bršljanica",
  "Mala Buna",
  "Mala Ciglena",
  "Mala Crkvina",
  "Mala Dapčevica",
  "Mala Dobra",
  "Mala Erpenja",
  "Mala Gora",
  "Mala Gorica",
  "Mala Gradusa",
  "Mala Hrastilnica",
  "Mala Huba",
  "Mala Jasenovača",
  "Mala Jazbina",
  "Mala Klisa",
  "Mala Kopanica",
  "Mala Kosa",
  "Mala Kosnica",
  "Mala Lešnica",
  "Mala Ludina",
  "Mala Milešina",
  "Mala Mlinska",
  "Mala Mučna",
  "Mala Ostrna",
  "Mala Paka",
  "Mala Paukova",
  "Mala Pačetina",
  "Mala Peratovica",
  "Mala Pisanica",
  "Mala Plana",
  "Mala Rakovica",
  "Mala Rasinjica",
  "Mala Rijeka",
  "Mala Solina",
  "Mala Subotica",
  "Mala Trapinska",
  "Mala Trnovitica",
  "Mala Učka",
  "Mala Vranovina",
  "Mala Črešnjevica",
  "Male Drage",
  "Male Mune",
  "Male Sesvete",
  "Male Srakane",
  "Maletići",
  "Malešević Selo",
  "Mali Banovac",
  "Mali Bastaji",
  "Mali Bilač",
  "Mali Botinovac",
  "Mali Brezovec",
  "Mali Brgud",
  "Mali Budići",
  "Mali Bukovec",
  "Mali Carevdar",
  "Mali Dol",
  "Mali Erjavec",
  "Mali Golji",
  "Mali Gorenec",
  "Mali Grabičani",
  "Mali Gradac",
  "Mali Grđevac",
  "Mali Iž",
  "Mali Jadrč",
  "Mali Komor",
  "Mali Kozinac",
  "Mali Lipovec",
  "Mali Lovrečan",
  "Mali Lošinj",
  "Mali Lug",
  "Mali Mihaljevec",
  "Mali Miletinac",
  "Mali Mlun",
  "Mali Modruš Potok",
  "Mali Obljaj",
  "Mali Otok",
  "Mali Pašijan",
  "Mali Podol",
  "Mali Poganac",
  "Mali Potočec",
  "Mali Prolog",
  "Mali Rastovac",
  "Mali Raven",
  "Mali Ston",
  "Mali Tabor",
  "Mali Turini",
  "Mali Vareški",
  "Mali Vrh Kamanjski",
  "Mali Vuković",
  "Mali Zdenci",
  "Malik",
  "Malinci",
  "Malino",
  "Malinovac",
  "Malinska",
  "Malička",
  "Maljevac",
  "Maljevačko Selište",
  "Maljkovo",
  "Malo Grablje",
  "Malo Gradišće",
  "Malo Korenovo",
  "Malo Krčevo",
  "Malo Polje",
  "Malo Selce",
  "Malo Selo",
  "Malo Trojstvo",
  "Malo Vukovje",
  "Malunje",
  "Mandalenčići",
  "Mandić Selo",
  "Mandićevac",
  "Mandli",
  "Mandre",
  "Manja Vas",
  "Manjadvorci",
  "Manjerovići",
  "Maovice",
  "Maranovići",
  "Marasi",
  "Maračići",
  "Marceljani",
  "Mareda",
  "Marenić",
  "Maretić",
  "Margečan",
  "Marigutić",
  "Marija Bistrica",
  "Marija Gorica",
  "Marija Magdalena",
  "Marija Trošt",
  "Marijanci",
  "Marina",
  "Marinbrod",
  "Marinci",
  "Marindolsko Brdo",
  "Marindvor",
  "Marinec",
  "Marinići",
  "Marinje Zemlje",
  "Marino Selo",
  "Marinovec",
  "Marinovec Zelinski",
  "Marići",
  "Marjanski Ivanovci",
  "Marjančaci",
  "Markoci",
  "Markovac",
  "Markovac Križevački",
  "Markovac Našički",
  "Marković Selo",
  "Markovići",
  "Markovo",
  "Markušbrijeg",
  "Markuševec Turopoljski",
  "Markušica",
  "Markušići",
  "Marlovac",
  "Marof",
  "Martijanec",
  "Martin",
  "Martina",
  "Martinac",
  "Martinci",
  "Martinci Miholjački",
  "Martinec Orehovički",
  "Martinić",
  "Martinišće",
  "Martinkovec",
  "Martinovići",
  "Martinovo Selo",
  "Martinska Ves",
  "Martinski",
  "Martinski Vrh",
  "Martinuševec",
  "Martinčići",
  "Martinšćica",
  "Martinšćina",
  "Martiša Ves",
  "Maruševec",
  "Marušići",
  "Maružini",
  "Marčan",
  "Marčana",
  "Marčani",
  "Marčelji",
  "Marčenegla",
  "Maršići",
  "Maslenjača",
  "Maslinica",
  "Mastelići",
  "Mastrinka",
  "Matase",
  "Matenci",
  "Materada",
  "Mateše",
  "Mateško Selo",
  "Matijaši",
  "Matijevići",
  "Matići",
  "Matković Mala",
  "Matohanci",
  "Matulini",
  "Matulji",
  "Maturovec",
  "Maurovići",
  "Mavrinci",
  "Mazin",
  "Mače",
  "Mački",
  "Mačkovac",
  "Mačkovec",
  "Mačkovo Selo",
  "Mašić",
  "Mašvina",
  "Mece",
  "Medak",
  "Medaki",
  "Medančići",
  "Medari",
  "Medinci",
  "Medovdolac",
  "Medsave",
  "Medulin",
  "Medveja",
  "Medveje",
  "Medven Draga",
  "Medvidići",
  "Medvidovića Draga",
  "Medviđa",
  "Mekiši kod Kaštelira",
  "Mekiši kod Vižinade",
  "Melinovac",
  "Meljan",
  "Melnica",
  "Melnice",
  "Merag",
  "Merenje",
  "Merhatovec",
  "Merišće",
  "Merolino Sikirevačko",
  "Metajna",
  "Metković",
  "Metohija",
  "Mezinovac",
  "Mečenčani",
  "Međa",
  "Međare",
  "Međedi",
  "Međeđak",
  "Međeđak Utinjski",
  "Međurača",
  "Međurače",
  "Međurić",
  "Migalovci",
  "Mihajlije",
  "Mihalić Selo",
  "Mihalj",
  "Mihaljekov Jarek",
  "Mihaljevac",
  "Mihaljevci",
  "Mihaljevići",
  "Mihanići",
  "Mihanovićev Dol",
  "Mihatovići",
  "Mihelići",
  "Miholašćica",
  "Miholec",
  "Miholjanec",
  "Miholjački Poreč",
  "Miholjsko",
  "Mihotići",
  "Mihovljan",
  "Mijaca",
  "Mijači",
  "Miklavec",
  "Mikleuš",
  "Mikleuška",
  "Mikluševci",
  "Mikovec",
  "Mikulići",
  "Miladini",
  "Milanezi",
  "Milani",
  "Milanlug",
  "Milanovac",
  "Milaševac",
  "Milaši",
  "Milekovo Selo",
  "Milinac",
  "Milivojevci",
  "Miličani",
  "Miljana",
  "Miljanovac",
  "Miljevac",
  "Miljevci",
  "Milna",
  "Milohnići",
  "Milotski Breg",
  "Milovčići",
  "Miloševac",
  "Milčetići",
  "Mimice",
  "Miočinovići",
  "Miočić",
  "Miranje",
  "Mirca",
  "Mirkopolje",
  "Mirkovac",
  "Mirkovci",
  "Mirkovec",
  "Mirkovec Breznički",
  "Mirlović Polje",
  "Mirlović Zagora",
  "Mislina",
  "Mitlo",
  "Mitrovac",
  "Mičetinac",
  "Mičetići",
  "Mičevec",
  "Mičijevac",
  "Mišinci",
  "Mišinka",
  "Miškovci",
  "Mišljenovac",
  "Mjesto Primišlje",
  "Mladenići",
  "Mlaka",
  "Mlaka Antinska",
  "Mlakovac",
  "Mlakva",
  "Mlinari",
  "Mlini",
  "Mlinište",
  "Mlinski Vinogradi",
  "Modrino Selo",
  "Modrovec",
  "Modruš",
  "Modrušani",
  "Mogorić",
  "Mohovo",
  "Mokošica",
  "Mokreš",
  "Mokrica Tomaševečka",
  "Mokrice",
  "Mokrice Miholečke",
  "Mokro Polje",
  "Molat",
  "Molunat",
  "Molve",
  "Molve Grede",
  "Molvice",
  "Momići",
  "Momjan",
  "Momčilovića Kosa",
  "Monterol",
  "Montižana",
  "Montovani",
  "Moravice",
  "Moravička Sela",
  "Morpolača",
  "Moseć",
  "Moslavačka Slatina",
  "Moslavina Podravska",
  "Most-Raša",
  "Mostari",
  "Motovun",
  "Movrač",
  "Močile",
  "Močići",
  "Mošanci",
  "Moštanica",
  "Mošćenice",
  "Mošćenička Draga",
  "Moščenica",
  "Možđenec",
  "Mracelj",
  "Mraclin",
  "Mratovo",
  "Mravince",
  "Mravinjac",
  "Mravnica",
  "Mračaj",
  "Mračaj Krstinjski",
  "Mračin",
  "Mrežnica",
  "Mrežničke Poljice",
  "Mrežnički Brest",
  "Mrežnički Brig",
  "Mrežnički Novaki",
  "Mrežnički Varoš",
  "Mrežničko Dvorište",
  "Mrgani",
  "Mrkopalj",
  "Mrkoplje",
  "Mrljane",
  "Mrzla Vodica",
  "Mrzli Dol",
  "Mrzljaki",
  "Mrzlo Polje",
  "Mrzlo Polje Žumberačko",
  "Mrzović",
  "Mrčevo",
  "Mugeba",
  "Mundanije",
  "Munije",
  "Munjava",
  "Munjava Modruška",
  "Muntić",
  "Muntrilj",
  "Muraj",
  "Murine",
  "Muričevec",
  "Mursko Središće",
  "Murter",
  "Murvica",
  "Musić",
  "Mustafina Klada",
  "Musulini",
  "Mutilić",
  "Mutvoran",
  "Mučići",
  "Mušalež",
  "Mušaluk",
  "Muškovci",
  "Mužilovčica",
  "Mužini",
  "Nadin",
  "Nadvoda",
  "Nadvrelo",
  "Nadvučnik",
  "Naglići",
  "Najevi",
  "Naklice",
  "Nakovanj",
  "Nard",
  "Nardući",
  "Nart Savski",
  "Narta",
  "Naselje Stjepana Radića",
  "Natkrižovljan",
  "Naudovac",
  "Našice",
  "Našičko Novo Selo",
  "Nebljusi",
  "Nebojan",
  "Nebojse",
  "Nedelišće",
  "Nedeljanec",
  "Nedešćina",
  "Negnar",
  "Negoslavci",
  "Negovec",
  "Nemetin",
  "Nemčevec",
  "Nenadići",
  "Neorić",
  "Nerezine",
  "Nerežišća",
  "Nespeš",
  "Neteka",
  "Netretić",
  "Nevest",
  "Nevinac",
  "Neviđane",
  "Nečujam",
  "Nijemci",
  "Nikšići",
  "Nin",
  "Ninski Stanovi",
  "Nisko",
  "Niza",
  "Nježić",
  "Njivice",
  "Normanci",
  "Noršić Selo",
  "Nos Kalik",
  "Noskovačka Dubrava",
  "Noskovci",
  "Nova Bukovica",
  "Nova Diklenica",
  "Nova Drenčina",
  "Nova Gradiška",
  "Nova Jošava",
  "Nova Kapela",
  "Nova Krivaja",
  "Nova Kršlja",
  "Nova Lipa",
  "Nova Lipovica",
  "Nova Ljeskovica",
  "Nova Mokošica",
  "Nova Pisanica",
  "Nova Ploščica",
  "Nova Rača",
  "Nova Sela",
  "Nova Subocka",
  "Nova Vas",
  "Nova Ves Petrijanečka",
  "Nova Šarovka",
  "Novaki",
  "Novaki Bistranski",
  "Novaki Bizovački",
  "Novaki Lipnički",
  "Novaki Motovunski",
  "Novaki Nartski",
  "Novaki Oborovski",
  "Novaki Ozaljski",
  "Novaki Pazinski",
  "Novaki Petrovinski",
  "Novaki Ravenski",
  "Novaki Šćitarjevski",
  "Novakovec",
  "Novakovec Bisaški",
  "Novalja",
  "Novačka",
  "Nove Hiže",
  "Novi Antunovac",
  "Novi Bezdan",
  "Novi Bešinci",
  "Novi Bolman",
  "Novi Bošnjani",
  "Novi Dol",
  "Novi Dvori Klanječki",
  "Novi Farkašić",
  "Novi Glog",
  "Novi Golubovec",
  "Novi Grabovac",
  "Novi Grad",
  "Novi Gradac",
  "Novi Jankovci",
  "Novi Lazi",
  "Novi Majur",
  "Novi Marof",
  "Novi Mihaljevci",
  "Novi Mikanovci",
  "Novi Pavljani",
  "Novi Perkovci",
  "Novi Senkovac",
  "Novi Skucani",
  "Novi Varoš",
  "Novi Vinodolski",
  "Novi Zagreb-istok ",
  "Novi Zagreb-zapad ",
  "Novi Zdenkovac",
  "Novi Čeminac",
  "Novi Đurđic",
  "Novi Štitnjak",
  "Novigrad",
  "Novigrad Podravski",
  "Novigrad na Dobri",
  "Novo Brdo",
  "Novo Brdo Mrežničko",
  "Novo Kusonje",
  "Novo Mjesto",
  "Novo Nevesinje",
  "Novo Petrovo Polje",
  "Novo Pračno",
  "Novo Selište",
  "Novo Selo",
  "Novo Selo Bosiljevsko",
  "Novo Selo Garešničko",
  "Novo Selo Glinsko",
  "Novo Selo Koreničko",
  "Novo Selo Lasinjsko",
  "Novo Selo Okićko",
  "Novo Selo Palanječko",
  "Novo Selo Perjasičko",
  "Novo Selo Podravsko",
  "Novo Selo Rok",
  "Novo Selo na Dravi",
  "Novo Selo Žumberačko",
  "Novo Topolje",
  "Novo Virje",
  "Novo Zvečevo",
  "Novo Čiče",
  "Novoselci",
  "Novoselec",
  "Novoseljani",
  "Novoselo Bilajsko",
  "Novoselo Trnovačko",
  "Novska",
  "Nunić",
  "Nurkovac",
  "Nuštar",
  "Obed",
  "Obedišće",
  "Obedišće Ježevsko",
  "Oblakovac",
  "Oborovo",
  "Oborovo Bistransko",
  "Obradovci",
  "Obrankovec",
  "Obrezina",
  "Obrež Kalnički",
  "Obrež Vivodinski",
  "Obrež Zelinski",
  "Obrh",
  "Obrovac",
  "Obrovac Sinjski",
  "Obrovnica",
  "Obrš",
  "Odra Sisačka",
  "Oglavci",
  "Ogulin",
  "Ogulinec",
  "Ohnići",
  "Okešinec",
  "Okić",
  "Oklaj",
  "Oključna",
  "Okoli",
  "Okreti",
  "Okrivje",
  "Okrug Donji",
  "Okrug Gornji",
  "Okrugli Vrh",
  "Okrugljača",
  "Okuje",
  "Okuklje",
  "Okunšćak",
  "Okučani",
  "Olib",
  "Oljasi",
  "Omamno",
  "Omanovac",
  "Omiš",
  "Omišalj",
  "Omsica",
  "Ondić",
  "Opanci",
  "Opasanjek",
  "Opatija",
  "Opatinec",
  "Opatovac",
  "Oplaznik",
  "Oporovec",
  "Oprisavci",
  "Oprič",
  "Oprtalj",
  "Opršinac",
  "Opuzen",
  "Općevac",
  "Orah",
  "Orahovica",
  "Oraovac",
  "Oratje",
  "Oravac",
  "Orašac",
  "Orbani",
  "Orbanići",
  "Ordanja",
  "Orebić",
  "Orehova Gorica",
  "Orehovec",
  "Orehovec Radobojski",
  "Orehovica",
  "Orešac",
  "Orešje",
  "Orešje Humsko",
  "Orešje Okićko",
  "Orihi",
  "Orijevac",
  "Oriovac",
  "Oriovčić",
  "Orič",
  "Orišje",
  "Orle",
  "Orlec",
  "Orlić",
  "Orljakovo",
  "Orljavac",
  "Orlovac",
  "Ornice",
  "Orolik",
  "Oroslavje",
  "Orovac",
  "Orubica",
  "Osekovo",
  "Osečka",
  "Osijek",
  "Osijek Vojakovački",
  "Oskoruš",
  "Oskorušno",
  "Oslići",
  "Osobjava",
  "Osoje",
  "Osojnik",
  "Osonjak",
  "Osor",
  "Osredci",
  "Osredek Desinićki",
  "Osredek Žumberački",
  "Ostojići",
  "Ostrogašica",
  "Ostrovica",
  "Ostrovo",
  "Ostrošinci",
  "Ostrožin",
  "Ostrvica",
  "Osunja",
  "Otavice",
  "Oteš",
  "Otišić",
  "Otkopi",
  "Otok",
  "Otok Nartski",
  "Otok Oštarijski",
  "Otok Svibovski",
  "Otok Virje",
  "Otok na Dobri",
  "Oton",
  "Otočac",
  "Otočka",
  "Otrić",
  "Otrić-Seoci",
  "Otrovanec",
  "Otruševec",
  "Otrčkovec",
  "Ovrlje",
  "Ovčara",
  "Ovčare",
  "Ozalj",
  "Ozdakovci",
  "Oćestovo",
  "Očura",
  "Ošljak",
  "Ošlje",
  "Oštarije",
  "Oštarski Stanovi",
  "Oštri Vrh",
  "Oštri Zid",
  "Oštrice",
  "Oštrobradić",
  "Ožegovci",
  "Pag",
  "Pagubice",
  "Pajari",
  "Pajkovići",
  "Paka",
  "Paklenica",
  "Pakovo Selo",
  "Pakoštane",
  "Pakrac",
  "Pakrani",
  "Paladini",
  "Palagruža",
  "Palanjek",
  "Palanjek Pokupski",
  "Palanka",
  "Palančani",
  "Palača",
  "Palešnik",
  "Palinovec",
  "Palit",
  "Palje Brdo",
  "Paljevina",
  "Paljevine",
  "Paljugi",
  "Paljuv",
  "Palovec",
  "Pamići",
  "Panjani",
  "Papinec",
  "Papići",
  "Paradiž",
  "Parg",
  "Paruževac",
  "Parčić",
  "Pasadur",
  "Pasanska Gorica",
  "Pasikovci",
  "Pasjak",
  "Patkovac",
  "Pauci",
  "Paukovac",
  "Paukovec",
  "Paulin Dvor",
  "Paulovac",
  "Paunovac",
  "Paučje",
  "Paušinci",
  "Paveki",
  "Pavićini",
  "Pavičići",
  "Pavković Selo",
  "Pavlin Kloštar",
  "Pavličani",
  "Pavlovac",
  "Pavlovac Vrebački",
  "Pavlovci",
  "Pavlovec Pregradski",
  "Pavlovec Ravenski",
  "Pavlovec Zabočki",
  "Pavlovčani",
  "Pavučnjak",
  "Paz",
  "Pazin",
  "Pačetin",
  "Pađene",
  "Pašin Potok",
  "Pašman",
  "Pašnik",
  "Pažurovec",
  "Pece",
  "Pecka",
  "Pecki",
  "Pedalj",
  "Pehardovac",
  "Peklenica",
  "Pengari",
  "Peničići",
  "Pepelana",
  "Peračko Blato",
  "Perci",
  "Perenci",
  "Peresiji",
  "Perini",
  "Perići",
  "Perjasica",
  "Perković",
  "Permani",
  "Perna",
  "Pernat",
  "Peroj",
  "Perovići",
  "Perušić",
  "Perušić Benkovački",
  "Peruški",
  "Peršaves",
  "Pesak",
  "Pesek",
  "Peskovec",
  "Petehi",
  "Peteranec",
  "Petina",
  "Petkov Breg",
  "Petkovac",
  "Petkovec Toplički",
  "Petlovac",
  "Petrakovo Brdo",
  "Petravec",
  "Petrača",
  "Petrijanec",
  "Petrijevci",
  "Petrinja",
  "Petrinjci",
  "Petričko Selo",
  "Petrova Gora",
  "Petrova Poljana",
  "Petrova Slatina",
  "Petrovci",
  "Petrovec",
  "Petrovija",
  "Petrovina",
  "Petrovina Turopoljska",
  "Petrovići",
  "Petrovo Selo",
  "Petrovsko",
  "Petrunići",
  "Petruš Vrh",
  "Petruševec",
  "Petrčane",
  "Pećane",
  "Pećarići",
  "Pećišće",
  "Pećno",
  "Pećurkovo Brdo",
  "Pešćenica",
  "Pešćenica Vinička",
  "Pešćenica-Žitnjak ",
  "Pešćeno",
  "Peščenik",
  "Pifari",
  "Pihovec",
  "Pijavičino",
  "Pila",
  "Pilatovci",
  "Piljenice",
  "Pinezići",
  "Pirakovec",
  "Piramatovci",
  "Pirelići",
  "Pirovac",
  "Pirovište",
  "Pisak",
  "Pisarovina",
  "Pitomača",
  "Pitve",
  "Pivare",
  "Pivnica Slavonska",
  "Pićan",
  "Piškorevci",
  "Piškovec",
  "Piškovica",
  "Pišćanovec",
  "Piščetke",
  "Pižinovac",
  "Pješčana Uvala",
  "Pješčanica",
  "Plajzi",
  "Planica",
  "Planina Kunićka",
  "Planina Skradska",
  "Plano",
  "Plastovo",
  "Plat",
  "Plavci",
  "Plavić",
  "Plavno",
  "Plavšinac",
  "Plaški",
  "Plemenitaš",
  "Plemenšćina",
  "Plesmo",
  "Pleternica",
  "Pleternički Mihaljevci",
  "Pleš",
  "Plešce",
  "Plešivica",
  "Plešići",
  "Pleškovec",
  "Plina Jezero",
  "Plisko Polje",
  "Plitvica Selo",
  "Plitvica Voćanska",
  "Plitvička Jezera",
  "Plitvički Ljeskovac",
  "Plomin",
  "Plomin Luka",
  "Plosna",
  "Plovanija",
  "Ploče",
  "Pločice",
  "Ploštine",
  "Pluska",
  "Pobjenik",
  "Pobrežje",
  "Pobri",
  "Pobrđani",
  "Podaca",
  "Podastrana",
  "Podašpilje",
  "Podbablje Gornje",
  "Podbilo",
  "Podbrest",
  "Podbrezovica",
  "Podbrežje",
  "Podbrđani Vojakovački",
  "Podbrđe",
  "Podcetin",
  "Podcrkavlje",
  "Podevčevo",
  "Podgaj Petrovski",
  "Podgajci Podravski",
  "Podgajec",
  "Podgarić",
  "Podgaće",
  "Podglavica",
  "Podgora",
  "Podgora Krapinska",
  "Podgora Turkovska",
  "Podgorani",
  "Podgorač",
  "Podgorci",
  "Podgorje",
  "Podgorje Bednjansko",
  "Podgorje Bistričko",
  "Podgorje Bračevačko",
  "Podgorje Jamničko",
  "Podgorje Krnjačko",
  "Podgradina",
  "Podgraj",
  "Podgrađe",
  "Podgrađe Podokićko",
  "Podhum",
  "Podhumlje",
  "Podi",
  "Podimoć",
  "Podine",
  "Podjales",
  "Podkilavac",
  "Podkuk",
  "Podlapača",
  "Podlug",
  "Podlužan",
  "Podmelnica",
  "Podmelnik",
  "Podobuče",
  "Podolec",
  "Podolje",
  "Podorljak",
  "Podosoje",
  "Podoštra",
  "Podprolog",
  "Podravlje",
  "Podravske Sesvete",
  "Podrebar",
  "Podrinje",
  "Podrujnica",
  "Podrute",
  "Podrvanj",
  "Podsedlo",
  "Podselje",
  "Podslemeni Lazi",
  "Podsljeme (Šestine-Gračani-Markuševec)",
  "Podsreće",
  "Podstena",
  "Podstene",
  "Podstrana",
  "Podstražje",
  "Podsused-Vrapče ",
  "Podturen",
  "Podum",
  "Podumci",
  "Podumol",
  "Podunavlje",
  "Podvinje",
  "Podvinje Miholečko",
  "Podvorec",
  "Podvornica",
  "Podvožić",
  "Podvrh",
  "Podvrško",
  "Podvučnik",
  "Podčudnič",
  "Podšpilje",
  "Pofuki",
  "Poganovci",
  "Pogančec",
  "Pojatno",
  "Pokasin",
  "Poklek",
  "Pokojec",
  "Pokrovnik",
  "Pokupsko",
  "Pokupsko Vratečko",
  "Polača",
  "Polače",
  "Police",
  "Police Pirišće",
  "Poličnik",
  "Poljana",
  "Poljana Biškupečka",
  "Poljana Križevačka",
  "Poljana Lekenička",
  "Poljana Sutlanska",
  "Poljana Vojnićka",
  "Poljana Čička",
  "Poljanak",
  "Poljanci",
  "Poljane",
  "Poljanec",
  "Poljani",
  "Poljanica Bistranska",
  "Poljanica Bistrička",
  "Poljanica Okićka",
  "Poljanska",
  "Poljanski Lug",
  "Poljančani",
  "Polje",
  "Polje Krapinsko",
  "Polje Ozaljsko",
  "Polje Čepić",
  "Poljica",
  "Poljica Kozička",
  "Poljice",
  "Pologi",
  "Poloje",
  "Polojski Varoš",
  "Polonje",
  "Polonje Tomaševečko",
  "Polubaše",
  "Polum",
  "Pomena",
  "Pomer",
  "Pomperovec",
  "Ponikvari",
  "Ponikve",
  "Ponor",
  "Ponor Korenički",
  "Ponorac",
  "Ponorac Perjasički",
  "Ponori",
  "Popova Luka",
  "Popovac",
  "Popovača",
  "Popovača Pazariška",
  "Popovci",
  "Popovec Kalnički",
  "Popović Brdo",
  "Popovići",
  "Popovići Žumberački",
  "Popovo Selo",
  "Porat",
  "Poreč",
  "Poređe",
  "Porozina",
  "Posavski Bregi",
  "Posavski Podgajci",
  "Posedarje",
  "Postira",
  "Postranje",
  "Pothum Plaščanski",
  "Potirna",
  "Potkobiljak",
  "Potkonje",
  "Potnjani",
  "Potok",
  "Potok Bosiljevski",
  "Potok Kalnički",
  "Potok Musulinski",
  "Potok Tounjski",
  "Potomje",
  "Potočani",
  "Potočec",
  "Potočnica",
  "Potpićan",
  "Potplaninsko Selo",
  "Potravlje",
  "Povelić",
  "Povile",
  "Povlja",
  "Povljana",
  "Pozla Gora",
  "Poznanovec",
  "Pozorac",
  "Počekaji",
  "Počitelj",
  "Požar",
  "Požari",
  "Požarkovec",
  "Požarnica",
  "Požega",
  "Požeška Koprivnica",
  "Požeški Brđani",
  "Požun",
  "Prapatnica",
  "Prapatnice",
  "Praporčan",
  "Prapoće",
  "Praputnjak",
  "Pravutina",
  "Pračana",
  "Prašćari",
  "Praščevac",
  "Pražnica",
  "Prebukovje",
  "Predavac",
  "Predavec",
  "Predavec Križevački",
  "Predošćica",
  "Pregrada",
  "Prekno",
  "Preko",
  "Prekobrdo",
  "Prekopa",
  "Prekopakra",
  "Prekoračani",
  "Prekrižje Plešivičko",
  "Prelog",
  "Prelošćica",
  "Premantura",
  "Premuda",
  "Prepolno",
  "Prepuštovec",
  "Prerovec",
  "Preseka",
  "Preseka Oborovska",
  "Preseka Ozaljska",
  "Preseka Petrovska",
  "Presečno",
  "Presečno Visočko",
  "Presika",
  "Preslatinci",
  "Prespa",
  "Pretetinec",
  "Pretkovec",
  "Pretoki",
  "Previž",
  "Prevlaka",
  "Prevršac",
  "Prezid",
  "Prečec",
  "Prečno",
  "Prgomelje",
  "Prgomet",
  "Prhati",
  "Prhci",
  "Prhovec",
  "Prhoć",
  "Prhutova Draga",
  "Pribanjci",
  "Pribislavec",
  "Pribić",
  "Pribić Crkveni",
  "Pribiševci",
  "Pribude",
  "Pribudić",
  "Pridraga",
  "Pridvorje",
  "Prigorec",
  "Prigorje",
  "Prigorje Brdovečko",
  "Prijeboj",
  "Prijeka",
  "Prijevor",
  "Prikraj",
  "Prikraj Križevački",
  "Priles",
  "Prilesje",
  "Prilipje",
  "Primorski Dolac",
  "Primošten",
  "Primošten Burnji",
  "Priselci",
  "Prisjeka",
  "Prisoje",
  "Pristava",
  "Pristava Krapinska",
  "Pristeg",
  "Privlaka",
  "Prizna",
  "Pričac",
  "Prišlin",
  "Prkačini",
  "Prkos",
  "Prkos Ivanićki",
  "Prkos Lasinjski",
  "Prkovci",
  "Prljevo",
  "Prnjarovac",
  "Prnjavor",
  "Prnjavor Lepavinski",
  "Prnjavor Čuntićki",
  "Prodani",
  "Prodin Dol",
  "Prodol",
  "Prokike",
  "Prokljuvani",
  "Promajna",
  "Prosenik",
  "Prosenik Gubaševski",
  "Prosenik Začretski",
  "Prosinec",
  "Protulipa",
  "Prović",
  "Prozor",
  "Prozorje",
  "Prožura",
  "Prožurska Luka",
  "Prud",
  "Prudnice",
  "Prugovac",
  "Prugovo",
  "Prvan Selo",
  "Prvinci",
  "Prvić Luka",
  "Prvić Šepurine",
  "Prvonožina",
  "Prvča",
  "Pršleti",
  "Pršurići",
  "Pucak",
  "Pucići",
  "Puhovo",
  "Puklica",
  "Pula",
  "Puljane",
  "Punat",
  "Punikve",
  "Punitovci",
  "Punta Križa",
  "Puntera",
  "Pupelica",
  "Pupnat",
  "Purga Bednjanska",
  "Purgarija Čepić",
  "Puričani",
  "Puska",
  "Pustakovec",
  "Pusti",
  "Pustike",
  "Pustodol",
  "Pustodol Orehovički",
  "Pustodol Začretski",
  "Putini",
  "Putičanje",
  "Putišići",
  "Putkovec",
  "Putniković",
  "Pučišća",
  "Pušava",
  "Pušina",
  "Puškarići",
  "Pušća",
  "Pušćine",
  "Pčelić",
  "Rab",
  "Raba",
  "Rabac",
  "Rabinja",
  "Radakovo",
  "Radatovići",
  "Radeljevo Selo",
  "Radetići",
  "Radešić",
  "Radigojna",
  "Radikovci",
  "Radina Gorica",
  "Radina Vas",
  "Radini",
  "Radinovo Brdo",
  "Radići",
  "Radljevac",
  "Radmani",
  "Radmanovac",
  "Radnovac",
  "Radoboj",
  "Radoišće",
  "Radonić",
  "Radonja",
  "Radonja Luka",
  "Radosavci",
  "Radovan",
  "Radovanci",
  "Radovani",
  "Radovanje",
  "Radovec",
  "Radovec Polje",
  "Radovin",
  "Radovčići",
  "Radočaj",
  "Radočaj Brodski",
  "Radočaji",
  "Radoševići",
  "Radoši kod Višnjana",
  "Radoši kod Žbandaja",
  "Radošinovci",
  "Radošić",
  "Radulec",
  "Radunić",
  "Raduč",
  "Radučić",
  "Rafaeli",
  "Rajakovići",
  "Rajevo Selo",
  "Rajino Polje",
  "Rajić",
  "Rajić Brdo",
  "Rajki",
  "Rajsavac",
  "Rajčići",
  "Rakalj",
  "Rakitje",
  "Rakitnica",
  "Rakitovec",
  "Rakitovica",
  "Rakotule",
  "Rakov Potok",
  "Rakovci",
  "Rakovec",
  "Rakovec Tomaševečki",
  "Rakovica",
  "Rakovičko Selište",
  "Ramanovci",
  "Ramljane",
  "Ramljani",
  "Randići",
  "Rapain Klanac",
  "Rapavel",
  "Raponji",
  "Rasinja",
  "Raskrižje",
  "Raskrižje Tihovo",
  "Raslina",
  "Rasna",
  "Rasohe",
  "Rasopasno",
  "Rastičevo",
  "Rastoka",
  "Rastoke",
  "Rastoki",
  "Rastovac",
  "Rastovac Budački",
  "Rastovača",
  "Rastušje",
  "Ratkov Dol",
  "Ratkovac",
  "Ratkovec",
  "Ratkovica",
  "Ratulje",
  "Rausovac",
  "Rava",
  "Ravan",
  "Ravna Gora",
  "Ravneš",
  "Ravni",
  "Ravni Dabar",
  "Ravnica",
  "Ravnice",
  "Ravnice Desinićke",
  "Ravninsko",
  "Ravno Brezje",
  "Ravno Rašće",
  "Ravča",
  "Razbojište",
  "Razdrto",
  "Razdrto Tuheljsko",
  "Razljev",
  "Razloge",
  "Razloški Okrug",
  "Razvor",
  "Razvođe",
  "Račak",
  "Račice",
  "Račinovci",
  "Račički Brijeg",
  "Račišće",
  "Račja Vas",
  "Rađenovci",
  "Raša",
  "Rašpor",
  "Raštević",
  "Rašćane",
  "Rašćane Gornje",
  "Rašćani",
  "Ražanac",
  "Ražanj",
  "Rebić",
  "Rebići",
  "Rebrovići",
  "Redovje",
  "Reka",
  "Remetinec",
  "Removac",
  "Rendulići",
  "Repaš",
  "Repinec",
  "Repićevo Selo",
  "Repišće",
  "Repno",
  "Repovec",
  "Repušnica",
  "Resnatac",
  "Resnik",
  "Resnik Bosiljevski",
  "Retkovci",
  "Retkovec Svibovečki",
  "Rezovac",
  "Rezovačke Krčevine",
  "Rečica",
  "Rečica Kriška",
  "Rešetar",
  "Rešetarevo",
  "Rešetari",
  "Reškovci",
  "Reštovo",
  "Reštovo Žumberačko",
  "Režanci",
  "Ribari",
  "Ribarići",
  "Ribić Breg",
  "Ribnica",
  "Ribnik",
  "Ribnjaci",
  "Ribnjak",
  "Ribnjačka",
  "Rijeka",
  "Rijeka Koprivnička",
  "Rijeka Voćanska",
  "Rijenci",
  "Rim",
  "Rimnjak",
  "Rinkovec",
  "Ripenda Kosi",
  "Ripenda Kras",
  "Ripenda Verbanci",
  "Risika",
  "Risvica",
  "Rit",
  "Rivalno",
  "Rivanj",
  "Rizvanuša",
  "Ričice",
  "Riđane",
  "Robadje",
  "Rodaljice",
  "Rodin Potok",
  "Rogač",
  "Rogačić",
  "Rogi",
  "Rogotin",
  "Rogovac",
  "Rogovići",
  "Rogoznica",
  "Rogočana",
  "Rogoža",
  "Rogulje",
  "Rojci",
  "Rojnići",
  "Rokovci",
  "Ropa",
  "Rosopajnik",
  "Rosulje",
  "Rovci",
  "Rovinj",
  "Rovinjsko Selo",
  "Roviška",
  "Rovištanci",
  "Rovišće",
  "Rovno",
  "Rozga",
  "Rozmajerovac",
  "Roč",
  "Ročko Polje",
  "Rošini",
  "Roškići",
  "Rožat",
  "Roždanik",
  "Rože",
  "Roženica",
  "Rožići",
  "Rožmanići",
  "Rtina",
  "Rtić",
  "Rubeši",
  "Ruda",
  "Rudani",
  "Rudanovac",
  "Rude",
  "Rude Pribićke",
  "Rudeži",
  "Rudina",
  "Rudine",
  "Rudopolje",
  "Rudopolje Bruvanjsko",
  "Rugvica",
  "Rujevac",
  "Rujevo",
  "Rukavac",
  "Rukljevina",
  "Rumin",
  "Runović",
  "Rupa",
  "Rupalj",
  "Rupe",
  "Rupeni",
  "Rusnica",
  "Ruča",
  "Rušani",
  "Ruševac",
  "Ruševica",
  "Ruševo",
  "Ruševo Krmpotsko",
  "Ruškovac",
  "Ruškovica",
  "Rušćica",
  "Ružić",
  "Ružići",
  "Sabljak Selo",
  "Sabljaki Modruški",
  "Sabljići",
  "Saborsko",
  "Sadikovac",
  "Sadilovac",
  "Salajci",
  "Salakovci",
  "Salambati",
  "Salež",
  "Sali",
  "Salinovec",
  "Salnik",
  "Salopek Luke",
  "Salopek Selo",
  "Salopeki Modruški",
  "Samarica",
  "Samatovci",
  "Samci",
  "Samobor",
  "Samoborec",
  "Samoborski Otok",
  "Santalezi",
  "Sapci",
  "Sapina Doca",
  "Saplunara",
  "Sapna",
  "Sarovo",
  "Sarvaš",
  "Saršoni",
  "Sasi",
  "Sasovac",
  "Sastavak",
  "Satnica",
  "Satnica Đakovačka",
  "Satrić",
  "Savar",
  "Savršćak",
  "Savska Cesta",
  "Savska Ves",
  "Savski Bok",
  "Savski Marof",
  "Savudrija",
  "Sažije",
  "Sedalce",
  "Sedlarica",
  "Sedramić",
  "Seget",
  "Seget Donji",
  "Seget Gornji",
  "Seget Vranjica",
  "Segovina",
  "Seketin",
  "Sekirevo Selo",
  "Sekirišće",
  "Sekulinci",
  "Sekulići",
  "Sela",
  "Sela Bosiljevska",
  "Sela Ravenska",
  "Sela Žakanjska",
  "Selakova Poljana",
  "Selanec",
  "Selca",
  "Selca kod Bogomolja",
  "Selca kod Starog Grada",
  "Selce",
  "Selce Žumberačko",
  "Selci",
  "Selci Križovljanski",
  "Selci Đakovački",
  "Selina",
  "Seline",
  "Selište",
  "Selište Drežničko",
  "Selište Kostajničko",
  "Selišće Sunjsko",
  "Seljaci",
  "Seljanec",
  "Selna",
  "Selnica",
  "Selnica Miholečka",
  "Selnica Podravska",
  "Selnica Psarjevačka",
  "Selnica Šćitarjevska",
  "Selnik",
  "Selno",
  "Selo",
  "Selo Sveti Marko",
  "Selsko Brdo",
  "Semeljci",
  "Semić",
  "Senj",
  "Senjska Draga",
  "Seoca",
  "Seoce",
  "Seoci",
  "Seona",
  "Sertić Poljana",
  "Sestrunj",
  "Sesvete",
  "Sesvete ",
  "Sesvete Ludbreške",
  "Setuš",
  "Severin",
  "Severin na Kupi",
  "Severovci",
  "Sevid",
  "Sibenik",
  "Sibinj",
  "Sibinj Krmpotski",
  "Sibić",
  "Sibokovac",
  "Sigetac",
  "Sigetec",
  "Sigetec Ludbreški",
  "Sikirevci",
  "Sikovo",
  "Silaš",
  "Silba",
  "Sinac",
  "Sinj",
  "Sinlije",
  "Sinožići",
  "Sirač",
  "Sirotići",
  "Sirova Katalena",
  "Sisak",
  "Sitno",
  "Sitno Donje",
  "Siverić",
  "Sivica",
  "Siča",
  "Sičane",
  "Siče",
  "Sičice",
  "Sišćani",
  "Sjeničak Lasinjski",
  "Sjeverovac",
  "Sječevac",
  "Skakavac",
  "Skela",
  "Skenderovci",
  "Skitača",
  "Skoblić Brdo",
  "Skrad",
  "Skradin",
  "Skradinsko Polje",
  "Skradnik",
  "Skradsko Selo",
  "Skrbčići",
  "Skrivena Luka",
  "Skukani",
  "Skupica",
  "Skvaranska",
  "Slabinja",
  "Sladojevački Lug",
  "Sladojevci",
  "Slakovci",
  "Slakovec",
  "Slana",
  "Slani Dol",
  "Slani Potok",
  "Slanje",
  "Slano",
  "Slapnica",
  "Slapno",
  "Slatina",
  "Slatina Pokupska",
  "Slatina Svedruška",
  "Slatine",
  "Slatinik Drenjski",
  "Slatinski Drenovac",
  "Slavagora",
  "Slavetić",
  "Slavica",
  "Slavonske Bare",
  "Slavonski Brod",
  "Slavonski Kobaš",
  "Slavonski Šamac",
  "Slavsko Polje",
  "Sleme Skradsko",
  "Slemenice",
  "Slime",
  "Slivarsko",
  "Slivnica",
  "Slivno",
  "Slivno Ravno",
  "Slivonja Jarek",
  "Sljeme",
  "Slobodna Vlast",
  "Slobodnica",
  "Sloboština",
  "Slokovec",
  "Slovinci",
  "Slovinska Kovačica",
  "Slum",
  "Slunj",
  "Slunjska Selnica",
  "Slunjski Moravci",
  "Slunjčica",
  "Smerovišće",
  "Smiljan",
  "Smiljansko Polje",
  "Smilčić",
  "Smišljak",
  "Smoković",
  "Smokovljani",
  "Smokrić",
  "Smokvica",
  "Smokvica Krmpotska",
  "Smolići",
  "Smoljanac",
  "Smoljanci",
  "Smoljanovci",
  "Smolonje",
  "Smrdelje",
  "Smrekari",
  "Smrečje",
  "Smrtić",
  "Smude",
  "Snašići",
  "Snos",
  "Soboli",
  "Sobočani",
  "Sobra",
  "Sokoli",
  "Sokolovac",
  "Soldatići",
  "Solin",
  "Soline",
  "Soljani",
  "Sonković",
  "Sop",
  "Sop Bukevski",
  "Sopač",
  "Sopjanska Greda",
  "Sopje",
  "Sopot",
  "Sopote",
  "Sopčić Vrh",
  "Sotin",
  "Sovari",
  "Sovinjak",
  "Sovinjska Brda",
  "Sovinjsko Polje",
  "Sovjak",
  "Sovski Dol",
  "Sočanica",
  "Sošice",
  "Sošići",
  "Spahići",
  "Sparagovići",
  "Sparednjak",
  "Spinčići",
  "Split",
  "Splitska",
  "Sračak",
  "Sračinec",
  "Srdinac",
  "Srebreno",
  "Srebrnići",
  "Srebrnjak",
  "Sredanci",
  "Sredice Gornje",
  "Srednja Diklenica",
  "Srednja Draga",
  "Srednja Gora",
  "Srednja Krašićevica",
  "Srednja Meminska",
  "Srednja Velika",
  "Srednjak",
  "Srednje Mokrice",
  "Srednje Prilišće",
  "Srednje Selo",
  "Srednji Dubovec",
  "Srednji Grahovljani",
  "Srednji Lipovac",
  "Srednji Mosti",
  "Srednji Poloj",
  "Sreser",
  "Srijane",
  "Srijedska",
  "Srijem",
  "Srijemske Laze",
  "Srima",
  "Srinjine",
  "Sroki",
  "Sršići",
  "Stajnica",
  "Stanetinec",
  "Stanica Roč",
  "Stanić",
  "Stanić Polje",
  "Stanići",
  "Staničići Žumberački",
  "Staniši",
  "Stanišovi",
  "Staništa",
  "Stankovac",
  "Stankovci",
  "Stanković",
  "Stankovo",
  "Stančić",
  "Stančići",
  "Stara Baška",
  "Stara Diklenica",
  "Stara Drenčina",
  "Stara Gradiška",
  "Stara Jošava",
  "Stara Kapela",
  "Stara Krivaja",
  "Stara Kršlja",
  "Stara Lipa",
  "Stara Ljeskovica",
  "Stara Marča",
  "Stara Novalja",
  "Stara Plošćica",
  "Stara Rača",
  "Stara Subocka",
  "Stara Sušica",
  "Stara Vas",
  "Stara Ves Košnička",
  "Stara Ves Petrovska",
  "Stara Ves Ravenska",
  "Starci",
  "Stare Plavnice",
  "Stari Bošnjani",
  "Stari Brod",
  "Stari Farkašić",
  "Stari Glog",
  "Stari Golubovec",
  "Stari Grabovac",
  "Stari Grad",
  "Stari Grad Žumberački",
  "Stari Gradac",
  "Stari Jankovci",
  "Stari Laz",
  "Stari Lazi",
  "Stari Majur",
  "Stari Mikanovci",
  "Stari Pavljani",
  "Stari Perkovci",
  "Stari Skucani",
  "Stari Slatinik",
  "Stari Zdenkovac",
  "Starigrad",
  "Starin",
  "Starine",
  "Starići",
  "Staro Petrovo Polje",
  "Staro Petrovo Selo",
  "Staro Pračno",
  "Staro Selo",
  "Staro Selo Topusko",
  "Staro Topolje",
  "Staro Čiče",
  "Staro Štefanje",
  "Starogradački Marof",
  "Starčevljani",
  "Staza",
  "Staševica",
  "Stažnjevec",
  "Stenjevec ",
  "Stepčići",
  "Stilja",
  "Stinica",
  "Stipan",
  "Stipanovci",
  "Stipernica",
  "Stivan",
  "Stobreč",
  "Stojavnica",
  "Stojdraga",
  "Stojmerić",
  "Stojčinovac",
  "Stolac",
  "Stomorska",
  "Ston",
  "Strahinje",
  "Strahinje Radobojsko",
  "Strahoninec",
  "Strana",
  "Stranići kod Lovreča",
  "Stranići kod Nove Vasi",
  "Stravča",
  "Strašnik",
  "Straža",
  "Straža Krapinska",
  "Stražanac",
  "Stražbenica",
  "Stražeman",
  "Strelec",
  "Strelečko",
  "Strezojevo",
  "Strgari",
  "Striježevica",
  "Strilčići",
  "Strizirep",
  "Strizivojna",
  "Strižičevac",
  "Strmac Pribićki",
  "Strmačka",
  "Strmec",
  "Strmec Bukevski",
  "Strmec Humski",
  "Strmec Podravski",
  "Strmec Remetinečki",
  "Strmec Stubički",
  "Strmec Sutlanski",
  "Strmen",
  "Strmendolac",
  "Strmica",
  "Strošinci",
  "Strpačići",
  "Struga",
  "Struga Banska",
  "Struga Nartska",
  "Stružani",
  "Stružec",
  "Stružec Posavski",
  "Stubalj",
  "Stubica",
  "Stubička Slatina",
  "Stubičke Toplice",
  "Studena",
  "Studenci",
  "Stupa",
  "Stupe",
  "Stupnički Kuti",
  "Stupnički Obrež",
  "Stupno",
  "Stupovača",
  "Subocka",
  "Subotica Podravska",
  "Sudaraž",
  "Sudovec",
  "Sudovčina",
  "Suha Katalena",
  "Suha Mlaka",
  "Suhaja",
  "Suhač",
  "Suhodol Budački",
  "Suhodol Zelinski",
  "Suhopolje",
  "Suhor",
  "Suhovare",
  "Suknovci",
  "Sukošan",
  "Sulkovci",
  "Sumartin",
  "Sunger",
  "Sunja",
  "Supetar",
  "Supetarska Draga",
  "Susak",
  "Sustjepan",
  "Sutina",
  "Sutinske Toplice",
  "Sutivan",
  "Sutomišćica",
  "Suvoj",
  "Suza",
  "Sućuraj",
  "Sučići",
  "Suđurađ",
  "Suša",
  "Sušac",
  "Sušanj Cesarički",
  "Sušci",
  "Sušine",
  "Sušići",
  "Sušobreg",
  "Sušobreg Bistrički",
  "Sužan",
  "Svedruža",
  "Sveta Ana",
  "Sveta Helena",
  "Sveta Jelena",
  "Sveta Katarina",
  "Sveta Lucija",
  "Sveta Marija",
  "Sveta Marija na Krasu",
  "Sveta Marina",
  "Sveta Nedelja",
  "Sveta Nedjelja",
  "Sveti Andrija",
  "Sveti Anton",
  "Sveti Bartol",
  "Sveti Bartul",
  "Sveti Donat",
  "Sveti Filip i Jakov",
  "Sveti Ilija",
  "Sveti Ivan",
  "Sveti Ivan Dobrinjski",
  "Sveti Ivan Zelina",
  "Sveti Ivan Žabno",
  "Sveti Jakov",
  "Sveti Josip",
  "Sveti Juraj",
  "Sveti Juraj u Trnju",
  "Sveti Križ",
  "Sveti Križ Začretje",
  "Sveti Lovreč Labinski",
  "Sveti Lovreč Pazenatički",
  "Sveti Martin",
  "Sveti Martin na Muri",
  "Sveti Martin pod Okićem",
  "Sveti Matej",
  "Sveti Petar",
  "Sveti Petar Mrežnički",
  "Sveti Petar Orehovec",
  "Sveti Petar na Moru",
  "Sveti Petar u Šumi",
  "Sveti Petar Čvrstec",
  "Sveti Rok",
  "Sveti Urban",
  "Sveti Vid Dobrinjski",
  "Sveti Vid-Miholjice",
  "Sveti Đurađ",
  "Sveti Đurđ",
  "Svetice",
  "Svetinja",
  "Svetičko Hrašće",
  "Svetoblažje",
  "Svetojurski Vrh",
  "Svetonedeljski Breg",
  "Svetvinčenat",
  "Svib",
  "Svibje",
  "Svibovec",
  "Svibovec Podravski",
  "Svilaj",
  "Svilna",
  "Svinca",
  "Svinica",
  "Svinica Krstinjska",
  "Svinišće",
  "Svinjarec",
  "Svinjarevci",
  "Svirče",
  "Svojić",
  "Svračica",
  "Svračkovo Selo",
  "Svrževo",
  "Taborište",
  "Tadini",
  "Tanac",
  "Tarno",
  "Tatar Varoš",
  "Tedrovec",
  "Tekić",
  "Temovec",
  "Tenja",
  "Teodorovac",
  "Tepljuh",
  "Terezino Polje",
  "Tiborjanci",
  "Tihočaj",
  "Tijarica",
  "Tikveš",
  "Timarci",
  "Tinj",
  "Tinjan",
  "Tisanić Jarek",
  "Tisno",
  "Tisovac",
  "Tisovac Žumberački",
  "Tići",
  "Tišina Erdedska",
  "Tišina Kaptolska",
  "Tiškovac Lički",
  "Tkalci",
  "Tkalec",
  "Tkon",
  "Tociljevac",
  "Tolić",
  "Tomaš",
  "Tomašanci",
  "Tomaševci",
  "Tomaševec",
  "Tomaševec Biškupečki",
  "Tomašica",
  "Tomašići",
  "Tomašnica",
  "Tomica",
  "Tomingaj",
  "Tominovac",
  "Tomislavovac",
  "Tomići",
  "Tomišići",
  "Tompojevci",
  "Tončići",
  "Topid",
  "Toplice",
  "Topličica",
  "Topoline",
  "Topolje",
  "Topolo",
  "Topolovac",
  "Topolovec",
  "Topolovec Pisarovinski",
  "Topolovica",
  "Topusko",
  "Toranj",
  "Tordinci",
  "Torjanci",
  "Torčec",
  "Totovec",
  "Tounj",
  "Tovarnik",
  "Točak",
  "Točak Perjasički",
  "Točionik",
  "Trakošćan",
  "Trapari",
  "Trbounje",
  "Trebež",
  "Trebovec",
  "Treglava",
  "Trema",
  "Tremušnjak",
  "Trenkovo",
  "Trepča",
  "Trešnjevka-jug ",
  "Trešnjevka-sjever ",
  "Treštanovci",
  "Trešćerovac",
  "Trg",
  "Trget",
  "Trgetari",
  "Trgovi",
  "Trgovišće",
  "Tribalj",
  "Triban",
  "Tribanj",
  "Tribulje",
  "Trilj",
  "Trinajstići",
  "Trn",
  "Trnakovac",
  "Trnava",
  "Trnava Cabunska",
  "Trnavac",
  "Trnbusi",
  "Trnjani",
  "Trnjanski Kuti",
  "Trnje",
  "Trnova",
  "Trnovac",
  "Trnovac Glinski",
  "Trnovac Sokolovački",
  "Trnovec",
  "Trnovec Desinićki",
  "Trnovi",
  "Trnovica",
  "Trnovitički Popovac",
  "Trnovo",
  "Trogir",
  "Trojeglava",
  "Trojstveni Markovac",
  "Trojvrh",
  "Trolokve",
  "Trombal",
  "Tropeti",
  "Trošmarija",
  "Trpanj",
  "Trpinja",
  "Trstenik",
  "Trstenik Nartski",
  "Trsteno",
  "Trtnik Glinski",
  "Trupinjak",
  "Trviž",
  "Trški Lazi",
  "Trški Vrh",
  "Tršće",
  "Tržić",
  "Tržić Primišljanski",
  "Tržić Tounjski",
  "Tugare",
  "Tugonica",
  "Tuhelj",
  "Tuheljske Toplice",
  "Tuhovec",
  "Tuk",
  "Tuk Bjelopoljski",
  "Tuk Mrkopaljski",
  "Tuk Vojni",
  "Tuliševica",
  "Tulnik",
  "Tupkovec",
  "Tupljak",
  "Tupčina",
  "Turanj",
  "Turanovac",
  "Turjaci",
  "Turjanski",
  "Turke",
  "Turkovići Ogulinski",
  "Turkovčina",
  "Turnašica",
  "Turnić",
  "Turnišće",
  "Turnišće Desinićko",
  "Turnišće Klanječko",
  "Turnovo",
  "Turopolje",
  "Turčenica",
  "Turčević Polje",
  "Turčin",
  "Turčić",
  "Turčišće",
  "Tusti Vrh",
  "Tučenik",
  "Tučepi",
  "Tušilović",
  "Tuškani",
  "Tuštevac",
  "Tužno",
  "Tvrda Reka",
  "Tvrđavica",
  "Uble",
  "Udbina",
  "Udbinja",
  "Udetin",
  "Udovičić",
  "Ugarci",
  "Ugljan",
  "Ugljane",
  "Uglješ",
  "Ugrini",
  "Uljanik",
  "Uljanički Brijeg",
  "Umag",
  "Umetić",
  "Umljanović",
  "Umol",
  "Umčani",
  "Unešić",
  "Unije",
  "Unčani",
  "Urinj",
  "Uskoci",
  "Uskoplje",
  "Ustrine",
  "Utinja",
  "Utinja Vrelo",
  "Utiskani",
  "Utolica",
  "Uzdolje",
  "Uštica",
  "Vabriga",
  "Vadalj",
  "Vadediji",
  "Vadina",
  "Vadreš",
  "Vaganac",
  "Vagovina",
  "Vajin Vrh",
  "Valbonaša",
  "Valenovac",
  "Valentinovo",
  "Valentići",
  "Valetić",
  "Valica",
  "Valići",
  "Valkarin",
  "Valpovo",
  "Valtura",
  "Valun",
  "Vanići",
  "Vantačići",
  "Varaštovac",
  "Varaždin",
  "Varaždin Breg",
  "Varaždinske Toplice",
  "Vardarac",
  "Vardica",
  "Varivode",
  "Varoš",
  "Varoš Bosiljevski",
  "Vasine Laze",
  "Vaćani",
  "Vaška",
  "Važminec",
  "Vedašić",
  "Vedrine",
  "Vedro Polje",
  "Vejaki",
  "Vela Luka",
  "Vela Traba",
  "Vela Učka",
  "Vela Voda",
  "Vele Drage",
  "Vele Mune",
  "Vele Srakane",
  "Veleniki",
  "Veleševec",
  "Veleškovec",
  "Veli Brgud",
  "Veli Dol",
  "Veli Golji",
  "Veli Iž",
  "Veli Lošinj",
  "Veli Mlun",
  "Veli Rat",
  "Veli Turini",
  "Velika",
  "Velika Babina Gora",
  "Velika Barna",
  "Velika Branjska",
  "Velika Bršljanica",
  "Velika Buna",
  "Velika Crkvina",
  "Velika Dapčevica",
  "Velika Erpenja",
  "Velika Glava",
  "Velika Gora",
  "Velika Gorica",
  "Velika Gradusa",
  "Velika Horvatska",
  "Velika Hrastilnica",
  "Velika Jamnička",
  "Velika Jasenovača",
  "Velika Jazbina",
  "Velika Klisa",
  "Velika Kopanica",
  "Velika Kosnica",
  "Velika Lešnica",
  "Velika Ludina",
  "Velika Milešina",
  "Velika Mlaka",
  "Velika Mlinska",
  "Velika Mučna",
  "Velika Ostrna",
  "Velika Paka",
  "Velika Peratovica",
  "Velika Petrovagorska",
  "Velika Pisanica",
  "Velika Plana",
  "Velika Popina",
  "Velika Rakovica",
  "Velika Rasinjica",
  "Velika Solina",
  "Velika Trapinska",
  "Velika Trnava",
  "Velika Trnovitica",
  "Velika Ves",
  "Velika Veternička",
  "Velika Vranovina",
  "Velika Črešnjevica",
  "Velike Brisnice",
  "Velike Sesvete",
  "Veliki Banovac",
  "Veliki Bastaji",
  "Veliki Bilač",
  "Veliki Botinovac",
  "Veliki Brezovec",
  "Veliki Bročanac",
  "Veliki Budići",
  "Veliki Bukovec",
  "Veliki Erjavec",
  "Veliki Gorenec",
  "Veliki Grabičani",
  "Veliki Gradac",
  "Veliki Grđevac",
  "Veliki Jadrč",
  "Veliki Komor",
  "Veliki Kozinac",
  "Veliki Lipovec",
  "Veliki Lovrečan",
  "Veliki Miletinac",
  "Veliki Modruš Potok",
  "Veliki Obljaj",
  "Veliki Otok",
  "Veliki Pašijan",
  "Veliki Poganac",
  "Veliki Potočec",
  "Veliki Prokop",
  "Veliki Prolog",
  "Veliki Rastovac",
  "Veliki Raven",
  "Veliki Vareški",
  "Veliki Vrh",
  "Veliki Vrh Kamanjski",
  "Veliki Zdenci",
  "Veliki Šušnjar",
  "Veliki Žitnik",
  "Veliko Brdo",
  "Veliko Korenovo",
  "Veliko Krčevo",
  "Veliko Polje",
  "Veliko Selce",
  "Veliko Svinjičko",
  "Veliko Trgovišće",
  "Veliko Trojstvo",
  "Veliko Vukovje",
  "Velim",
  "Velimirovac",
  "Velinci",
  "Velić",
  "Velići",
  "Veliškovci",
  "Veljaki",
  "Veljun",
  "Veljun Primorski",
  "Veljunska Glina",
  "Veljunski Ponorac",
  "Velo Grablje",
  "Velušić",
  "Venac Mrežnički",
  "Venje",
  "Veprinac",
  "Vera",
  "Verunić",
  "Vesela",
  "Veselići",
  "Veternica",
  "Vetovo",
  "Vezac",
  "Vezišće",
  "Većeslavec",
  "Vid",
  "Vidalići",
  "Videkić Selo",
  "Vidonje",
  "Vidovac Cesarički",
  "Vidovci",
  "Vidovec",
  "Vidovec Krapinski",
  "Vidovec Petrovski",
  "Vidovići",
  "Vidrenjak",
  "Vidulini",
  "Viganj",
  "Vijenac Barilovićki",
  "Vilanci",
  "Vilanija",
  "Viletinec",
  "Vilić Selo",
  "Viljevo",
  "Vina",
  "Vinagora",
  "Vinalić",
  "Vinarec",
  "Vinež",
  "Vini Vrh",
  "Vinica",
  "Vinica Breg",
  "Vinine",
  "Vinipotok",
  "Vinično",
  "Vinišće",
  "Vinjerac",
  "Vinkovački Banovci",
  "Vinkovci",
  "Vinkovec",
  "Vinkuran",
  "Vinogradci",
  "Vinogradi Ludbreški",
  "Vinovac",
  "Vinski Vrh",
  "Vinterovec",
  "Vintijan",
  "Vir",
  "Virje",
  "Virje Križovljansko",
  "Virovitica",
  "Vis",
  "Visoka",
  "Visoka Greda",
  "Visoko",
  "Visovi",
  "Visoće",
  "Visočane",
  "Visočani",
  "Visuć",
  "Vitaljina",
  "Vitešinec",
  "Vitunj",
  "Vivodina",
  "Viča Sela",
  "Viškovci",
  "Viškovići",
  "Viškovo",
  "Višnjan",
  "Višnjevac",
  "Višnjevec",
  "Višnjevec Podvrški",
  "Višnjica",
  "Višći Vrh",
  "Vižanovec",
  "Vižinada",
  "Vižintini",
  "Vižintini Vrhi",
  "Vižovlje",
  "Vladimirovac",
  "Vladislavci",
  "Vladisovo",
  "Vlahović",
  "Vlaislav",
  "Vlaka",
  "Vlatkovac",
  "Vlašić Brdo",
  "Vlašići",
  "Vlaškovec",
  "Vode",
  "Vodena Draga",
  "Vodice",
  "Vodnjan",
  "Vodostaj",
  "Vodoteč",
  "Vodovađa",
  "Vojakovac",
  "Vojišnica",
  "Vojlovica",
  "Vojnić",
  "Vojnić Sinjski",
  "Vojnić-Breg",
  "Vojnovac",
  "Vojnovec Kalnički",
  "Vojnovec Loborski",
  "Vojnović Brdo",
  "Vojsak",
  "Vojvodinec",
  "Volarice",
  "Volavec",
  "Volavje",
  "Volinja",
  "Voljavec Riječki",
  "Voloder",
  "Vorkapić",
  "Vozilići",
  "Voćarica",
  "Voćin",
  "Vođinci",
  "Voštane",
  "Vošteni",
  "Vrabač",
  "Vrana",
  "Vraneševci",
  "Vranik",
  "Vranić",
  "Vranići kod Višnjana",
  "Vranići kod Vižinade",
  "Vranja",
  "Vranjak Žumberački",
  "Vranje Selo",
  "Vranjic",
  "Vrankovec",
  "Vranojelje",
  "Vranov Dol",
  "Vranovača",
  "Vranovci",
  "Vranovine",
  "Vrata",
  "Vrataruša",
  "Vratečko",
  "Vratišinec",
  "Vratnik",
  "Vratnik Samoborski",
  "Vratno Otok",
  "Vrbani",
  "Vrbanj",
  "Vrbanja",
  "Vrbanovec",
  "Vrbanska Draga",
  "Vrbica",
  "Vrbišnica",
  "Vrbje",
  "Vrbnik",
  "Vrbno",
  "Vrboska",
  "Vrbova",
  "Vrbovac",
  "Vrbovec",
  "Vrbovec Samoborski",
  "Vrbovečki Pavlovec",
  "Vrbovljani",
  "Vrbovo",
  "Vrbovo Posavsko",
  "Vrbovsko",
  "Vrebac",
  "Vrelo Koreničko",
  "Vrećari",
  "Vrgada",
  "Vrgorac",
  "Vrh",
  "Vrh Brodski",
  "Vrh Lašići",
  "Vrh Letovanićki",
  "Vrh Martinšćice",
  "Vrh Visočki",
  "Vrhi Pregradski",
  "Vrhi Vinagorski",
  "Vrhjani",
  "Vrhova Gorica",
  "Vrhovac",
  "Vrhovac Sokolovački",
  "Vrhovački Sopot",
  "Vrhovci",
  "Vrhovec",
  "Vrhovec Bednjanski",
  "Vrhovina",
  "Vrhovine",
  "Vrhovljan",
  "Vrhovčak",
  "Vrisnik",
  "Vrlika",
  "Vrnjak",
  "Vrpile",
  "Vrpolje",
  "Vrsar",
  "Vrsi",
  "Vrsine",
  "Vrsno",
  "Vrtlinovec",
  "Vrtlinska",
  "Vrtnjakovec",
  "Vrvari",
  "Vrzići",
  "Vrčin Dol",
  "Vrčići",
  "Vrškovac",
  "Vržnaveri",
  "Vuglovec",
  "Vugrišinec",
  "Vujići Vojakovački",
  "Vujnovići",
  "Vuka",
  "Vukanci",
  "Vukanovec",
  "Vukelići",
  "Vuketić",
  "Vukmanić",
  "Vukoder",
  "Vukojevci",
  "Vukojevica",
  "Vukomerić",
  "Vukosavljevica",
  "Vukova Gorica",
  "Vukovar",
  "Vukovec",
  "Vukovije",
  "Vukovina",
  "Vukovje Zelinsko",
  "Vukovo Brdo",
  "Vukovo Selo",
  "Vukovoj",
  "Vukoševac",
  "Vuksani",
  "Vukšin Šipak",
  "Vukšinec Riječki",
  "Vukšić",
  "Vularija",
  "Vulišinec",
  "Vurot",
  "Vučak",
  "Vučani",
  "Vučetinec",
  "Vučevci",
  "Vučevica",
  "Vučilčevo",
  "Vučinići",
  "Vučipolje",
  "Vučjak Feričanački",
  "Vučnik",
  "Zablatje",
  "Zablatje Posavsko",
  "Zabok",
  "Zaborsko Selo",
  "Zabrežani",
  "Zabrđe",
  "Zabukovac",
  "Zadar",
  "Zadobarje",
  "Zadravec",
  "Zadrkovec",
  "Zadubravlje",
  "Zadvarje",
  "Zaglav",
  "Zagolik",
  "Zagora",
  "Zagore",
  "Zagorje",
  "Zagorska Sela",
  "Zagrad",
  "Zagradci",
  "Zagraj",
  "Zagrađe",
  "Zagvozd",
  "Zahrt",
  "Zaistovec",
  "Zajačko Selo",
  "Zajci",
  "Zajezda",
  "Zaklepica",
  "Zaklopac",
  "Zaklopatica",
  "Zaklopača",
  "Zakopa",
  "Zakorenje",
  "Zakrajc Brodski",
  "Zakrajc Turkovski",
  "Zakučac",
  "Zalesina",
  "Zaloj",
  "Zalug",
  "Zaluka",
  "Zaluka Lipnička",
  "Zaluki",
  "Zalužje",
  "Zalužnica",
  "Zamask",
  "Zamaski Dol",
  "Zambratija",
  "Zamladinec",
  "Zamlaka",
  "Zamlača",
  "Zamost",
  "Zamost Brodski",
  "Zamršje",
  "Zankovci",
  "Zaostrog",
  "Zapeć",
  "Zapoljak",
  "Zapolje",
  "Zapolje Brodsko",
  "Zaprešić",
  "Zapuntel",
  "Zapužane",
  "Zaraće",
  "Zarečje",
  "Zarilac",
  "Zasadbreg",
  "Zasiok",
  "Zastenice",
  "Zastolje",
  "Zastražišće",
  "Zatka Čepić",
  "Zaton",
  "Zaton Doli",
  "Zaton Obrovački",
  "Zaumol",
  "Zavala",
  "Zaveščak",
  "Zavojane",
  "Zavođe",
  "Zavrelje",
  "Zavrh",
  "Završje",
  "Završje Belečko",
  "Završje Loborsko",
  "Završje Netretićko",
  "Završje Podbelsko",
  "Završje Začretsko",
  "Zbelava",
  "Zbičina",
  "Zbišina",
  "Zbjeg",
  "Zbjegovača",
  "Zdenac",
  "Zdenci",
  "Zdenci Brdovečki",
  "Zdenčac",
  "Zdenčec",
  "Zdihovo",
  "Zebanec Selo",
  "Zeci",
  "Zelendvor",
  "Zelengrad",
  "Zeleno Polje",
  "Zelin Crnoluški",
  "Zelin Mrzlovodički",
  "Zelina Breška",
  "Zelovo",
  "Zelčin",
  "Zemunik Donji",
  "Zemunik Gornji",
  "Zetkan",
  "Zečev Varoš",
  "Zečevo",
  "Zečevo Rogozničko",
  "Zgališće",
  "Zgrabljići",
  "Zgurić Brdo",
  "Zidarići",
  "Zimić",
  "Zinajevac",
  "Zirčica",
  "Zlarin",
  "Zlatar",
  "Zlatar-Bistrica",
  "Zlatna Greda",
  "Zleć",
  "Zlobin",
  "Zlogonje",
  "Zmajevac",
  "Zmijavci",
  "Znož",
  "Zokov Gaj",
  "Zoljan",
  "Zoljani",
  "Zoretići",
  "Zoričići",
  "Zorkovac",
  "Zorkovac Vivodinski",
  "Zorkovac na Kupi",
  "Zrenj",
  "Zrin",
  "Zrinj Lukački",
  "Zrinska",
  "Zrinska Draga",
  "Zrinski Brđani",
  "Zrinski Topolovac",
  "Zrinšćina",
  "Zrmanja",
  "Zrmanja Vrelo",
  "Zubovići",
  "Zut",
  "Zvekovac",
  "Zvekovica",
  "Zverinac",
  "Zvečaj",
  "Zvečanje",
  "Zvijerci",
  "Zvjerinac",
  "Zvoneće",
  "Zvonik",
  "Zvonimirovac",
  "Zvonimirovo",
  "grabovo (dio)",
  "Ćelije",
  "Ćikovići",
  "Ćore",
  "Ćosinac",
  "Ćosine Laze",
  "Ćuić Brdo",
  "Ćunski",
  "Ćurlovac",
  "Ćusi",
  "Čabar",
  "Čabdin",
  "Čabraji",
  "Čabrunići",
  "Čaglin",
  "Čaire",
  "Čajkovci",
  "Čajkovica",
  "Čajkovići",
  "Čakanec",
  "Čakovci",
  "Čakovec",
  "Čalinec",
  "Čamagajevci",
  "Čambarelići",
  "Čamerovac",
  "Čanak",
  "Čanjevo",
  "Čapljani",
  "Čaporice",
  "Čaprginci",
  "Čara",
  "Čatrnja",
  "Čavle",
  "Čavlovica",
  "Čavoglave",
  "Čazma",
  "Čačinci",
  "Čačvina",
  "Čađavac",
  "Čađavica",
  "Čađavički Lug",
  "Čedanj",
  "Čeglje",
  "Čehići",
  "Čehovec",
  "Čelikovići",
  "Čelina",
  "Čelopeci",
  "Čemernica",
  "Čemernica Lonjska",
  "Čeminac",
  "Čenkovo",
  "Čepelovac",
  "Čepikuće",
  "Čepin",
  "Čepinski Martinci",
  "Čepić",
  "Čepljani",
  "Čeralije",
  "Červar-Porat",
  "Červari",
  "Čestijanec",
  "Česvinica",
  "Četekovac",
  "Četvrtkovac",
  "Čečavac",
  "Čečavački Vučjak",
  "Češljakovci",
  "Čibača",
  "Čigoč",
  "Čilipi",
  "Čingi-Lingi",
  "Čiritež",
  "Čisla",
  "Čista Mala",
  "Čista Mlaka",
  "Čista Velika",
  "Čitluk",
  "Čičkovina",
  "Čižići",
  "Čojlug",
  "Čojluk",
  "Čokadinci",
  "Čovac",
  "Čovići",
  "Čremušnica",
  "Čret",
  "Čret Bisaški",
  "Čret Posavski",
  "Črečan",
  "Črešnjevec",
  "Črešnjevo",
  "Črnec Biškupečki",
  "Črnec Dugoselski",
  "Črnec Rugvički",
  "Črnica",
  "Črnile",
  "Črnilovec",
  "Črnkovci",
  "Črnkovec",
  "Črnomerec ",
  "Črnčevec",
  "Črvar",
  "Čujića Krčevina",
  "Čuki",
  "Čukovec",
  "Čukur",
  "Čunkova Draga",
  "Čuntić",
  "Čurilovec",
  "Čučak",
  "Čučići",
  "Čuši",
  "Čvrljevo",
  "Đakovo",
  "Đelekovec",
  "Đeletovci",
  "Đevrske",
  "Đivan",
  "Đuba",
  "Đulovac",
  "Đurin Potok",
  "Đurinići",
  "Đurinovec",
  "Đurići",
  "Đuričić",
  "Đurmanec",
  "Đurđanci",
  "Đurđenovac",
  "Đurđevac",
  "Đurđic",
  "Šag",
  "Šagovina Cernička",
  "Šagovina Mašićka",
  "Šagudovec",
  "Šajini",
  "Šakanlije",
  "Šalamunić",
  "Šalamunovec",
  "Šalovec",
  "Šandorovec",
  "Šandrovac",
  "Šapjane",
  "Šaptinovci",
  "Šarengrad",
  "Šarić Struga",
  "Šarići",
  "Šartovac",
  "Šatornja",
  "Šaš",
  "Šaša",
  "Šaševa",
  "Šaševo",
  "Šebreki",
  "Šeganovac",
  "Šegestin",
  "Šegotići",
  "Šeketino Brdo",
  "Šelovec",
  "Šemovci",
  "Šemovec",
  "Šemovec Breški",
  "Šenkovec",
  "Šeovci",
  "Šeovica",
  "Šepci Podstenski",
  "Šeraje",
  "Šestak Brdo",
  "Šestanovac",
  "Ševalj",
  "Šećerana",
  "Šibenik",
  "Šibice",
  "Šibine",
  "Šibovac",
  "Šijanec",
  "Šije",
  "Šiljakovina",
  "Šilješki",
  "Šiljki",
  "Šiljkovača",
  "Šilo",
  "Šimatovo",
  "Šimljana",
  "Šimljanica",
  "Šimljanik",
  "Šimraki",
  "Šimunci",
  "Šimuni",
  "Šinkovica Bednjanska",
  "Šinkovica Šaška",
  "Šipanska Luka",
  "Šipački Breg",
  "Šipki",
  "Šiptari",
  "Širinci",
  "Širine",
  "Širinec",
  "Širitovci",
  "Široka Kula",
  "Široka Rijeka",
  "Široke",
  "Široko Polje",
  "Široko Selo",
  "Šivati",
  "Šišan",
  "Šišinec",
  "Šiškovci",
  "Šišljavić",
  "Škabrnja",
  "Škalić Zagorski",
  "Škaljevica",
  "Škalnica",
  "Škare",
  "Škarićevo",
  "Škarnik",
  "Škodinovac",
  "Škopljak",
  "Škrabutnik",
  "Škriljevec",
  "Škrinjari",
  "Škrip",
  "Škrljevo",
  "Škropeti",
  "Škuljari",
  "Šlivnjak",
  "Šljivovac",
  "Šljivoševci",
  "Šmrika",
  "Šnjegavić",
  "Šodići",
  "Šodolovci",
  "Šoići",
  "Šopot",
  "Šopron",
  "Šorgi",
  "Šorići",
  "Španat",
  "Španovica",
  "Špehari",
  "Špigelski Breg",
  "Špiranec",
  "Špičkovina",
  "Špišić Bukovica",
  "Štaglinec",
  "Štakorovec",
  "Štakorovica",
  "Štedrica",
  "Štefanci",
  "Štefanec",
  "Štefanje",
  "Šterna",
  "Štifanići",
  "Štikada",
  "Štikovo",
  "Štirkovac",
  "Štitnjak",
  "Štivica",
  "Štokovci",
  "Štrigova",
  "Štrigovec",
  "Štrmac",
  "Štrped",
  "Štrucljevo",
  "Štrukovec",
  "Štuparje",
  "Štuti",
  "Šulinec",
  "Šumanovci",
  "Šumarina",
  "Šumber",
  "Šumet",
  "Šumetlica",
  "Šumećani",
  "Šumeće",
  "Šumeđe",
  "Šuplja Lipa",
  "Šurdovec",
  "Šušelj Brijeg",
  "Šušnjari",
  "Šušnjevci",
  "Šušnjevica",
  "Šušnjići",
  "Švajcarnica",
  "Švaljkovec",
  "Švica",
  "Švrakarica",
  "Šćapovec",
  "Šćepanje",
  "Šćitarjevo",
  "Šćrbinec",
  "Šćulac",
  "Šćulci",
  "Žabica",
  "Žabjak",
  "Žabljak",
  "Žabnica",
  "Žabnik",
  "Žabnjak",
  "Žabnjača",
  "Žabno",
  "Žaborić",
  "Žagrići",
  "Žagrović",
  "Žakanje",
  "Žakule",
  "Žamarija",
  "Žarovnica",
  "Žažina",
  "Žažvić",
  "Žbandaj",
  "Ždala",
  "Ždralovi",
  "Ždrapanj",
  "Ždrelac",
  "Žedno",
  "Žejane",
  "Žejinci",
  "Železna Gora",
  "Želiski",
  "Željava",
  "Željeznica",
  "Željezno Žumberačko",
  "Žena Glava",
  "Ženodraga",
  "Žestilac",
  "Žeževica",
  "Žgaljići",
  "Žgombići",
  "Žibrinovec",
  "Židovinjak",
  "Žigerovci",
  "Žigrovec",
  "Žikovići",
  "Žirje",
  "Žiroslavje",
  "Žitarka",
  "Žitkovčica",
  "Žitnić",
  "Žitomir",
  "Živaja",
  "Živike",
  "Živković Kosa",
  "Živogošće",
  "Žiškovec",
  "Žlebec Gorički",
  "Žlebec Pušćanski",
  "Žlebina",
  "Žman",
  "Žminj",
  "Žnjidarići",
  "Žonti",
  "Žreme",
  "Žrnovac",
  "Žrnovnica",
  "Žrnovo",
  "Žuberkovac",
  "Žubrica",
  "Žubrinci",
  "Žudetići",
  "Žuknica",
  "Žukovec",
  "Žuljana",
  "Žumberak",
  "Žunci",
  "Žuntići",
  "Župa",
  "Župa Srednja",
  "Županec",
  "Županići",
  "Županja",
  "Županje",
  "Župić",
  "Žurkovo",
  "Žuta Lokva",
  "Žutnica",
  "Žužići",
];

const countyArr = [
  "Bjelovarsko-Bilogorska Županija",
  "Brodsko-Posavska Županija",
  "Dubrovacko-Neretvanska Županija",
  "Istarska Županija",
  "Karlovačka Županija",
  "Koprivničko-Križevacka Županija",
  "Krapinsko-Zagorska Županija",
  "Ličko-Senjska Županija",
  "Međimurska Županija",
  "Osječko-Baranjska Županija",
  "Požeško-Slavonska Županija",
  "Primorsko-Goranska Županija",
  "Šibensko-Kninska Županija",
  "Sisačko-Moslavačka Županija",
  "Splitsko-Dalmatinska Županija",
  "Varaždinska Županija",
  "Virovitičko-Podravska Županija",
  "Vukovarsko-Srijemska Županija",
  "Zadarska Županija",
  "Zagreb",
  "Zagrebačka Županija",
];

const countyDefaultValue = [{ value: "", label: "Odaberite županiju" }];
const mappedCounties = countyArr.map((item) => {
  return { value: item, label: item };
});
export const counties = countyDefaultValue.concat(mappedCounties);

const cityDefaultValue = [{ value: "", label: "Odaberite grad" }];
const mappedCities = cityArr.map((item) => {
    return { value: item, label: item };
  });
  export const cities = cityDefaultValue.concat(mappedCities);
  
